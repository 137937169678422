import React,{useState,useEffect} from "react";
import MedicLogo from '../icons/medic.svg';

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Show from "./Show.js";
import InputSelect from "./form/InputSelect.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getRoles } from "@testing-library/react";

import InputText from "./form/InputText.js";
import { apiClient } from "../apiCall";

  export const defaultEventsData={

	loaded:false,
	addnew:false,
	data:[],
	edit:false
	
	
};
export const DefaultRequestErrors={

	errors:null
}


export default function UsersEditor() {
	const { state, dispatch } = useStore();

	const [EventsData, setEventsData] = useState({
		...defaultEventsData
		
    });
	const [RequestErrors,setRequestErrors] = useState({
		...DefaultRequestErrors
			
		});
	const apiCall = apiClient(state, dispatch);
	useEffect(() => {
		fetch( config.apiUrl+"/users",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setEventsData({data:[...data],orginal:JSON.parse(JSON.stringify(([...data]))),loaded:true}))

	  }, []);
	  const getGenres=(genres)=>{

			return genres.map((g,index) =>{

				return <span>{g.texts.fi.name}{(index+1!=genres.length)?", ":null}</span>
			});

	  };
	  const changeEditMode=()=>{
		setRequestErrors({errors:null});
		if(EventsData.edit){

			
			let org=JSON.parse(JSON.stringify(([...EventsData.orginal])))
			
			setEventsData({...EventsData,edit:false,data:[...org]})
			//setEventData({genres:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

		}else{
			setEventsData({...EventsData,edit:!EventsData.edit})
		}
	};

	const renderErrors=()=>{

		let error_array={...RequestErrors.errors}

		let errors=Object.keys(error_array).map(function(key) {
			
			return error_array[key].map(function(v,index) {
				return  <div className="error">{v}</div>
			});

		});

		return <div className="errors">{errors}</div>



	}


	  const getRole=(u)=>{


		if(u.role==1){
			return "Admin";
		}
		if(u.role==2){
			return "Manager"; //kaikki muut paitsi tiedostot
		}
		if(u.role==3){
			return "Staff"; //vain show datat
		}
		return null;
	  }
	  const newlist=(d)=>{

		setEventsData({...EventsData,data:[...d],addnew:false})
	  }
	 
	  console.log(EventsData.data);
	  const changeInputValue=(name,value,index)=>{
		let data=[...EventsData.data]
		
		
		data[index][name]=value;

		
		setEventsData({...EventsData,data:[...data]});




    };
	const changeRole=(name,value,index)=>{

		let data=[...EventsData.data]
		
		
		data[index][name]=value;

		
		setEventsData({...EventsData,data:[...data]});

	}

	const roleOptions=()=>{

		

			
			return [{id:1,name:"Admin"},{id:2,name:"Manager"},{id:3,name:"Staff"}]
	
		

	};
		const rows=EventsData.data.map((event,index) =>{

			return <tr key={index}>
				<td><InputText onChange={changeInputValue} index={index} data={event}  name="name" edit={EventsData.edit}/>
</td>
<td><InputText onChange={changeInputValue} index={index} data={event}  name="email" edit={EventsData.edit}/>
</td>

				
				
				<td>{EventsData.edit?<InputText onChange={changeInputValue} index={index} data={event}  name="pass" edit={EventsData.edit}/>:"****"}</td>
				<td>{EventsData.edit?<InputSelect edit={true}  zero={"Select role"} index={index} onChange={changeRole} options={roleOptions()} name="role" data={event}/>:getRole(event)}</td>
				
				{EventsData.edit?<td></td>:null}
				{/*<td><Link to={"/shows/"+event.id}>{(event.showtimes.length)}</Link></td>*/}
			
			</tr>;
		  
		});

		const changeAddMode=()=>{

	
			setEventsData({...EventsData,addnew:!EventsData.addnew})
	
	 };
	 const tryToSave=()=>{

		setRequestErrors({errors:null});
let url="users";
	let data={users:[...EventsData.data]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			setEventsData({...EventsData,"edit":false});
			setRequestErrors({errors:null});
			
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    



	 };
	 const addNew=()=>{


		let n={name:null,pass:null,role:null,email:null};

		let data=[n,...EventsData.data]
		
		
	

		
		setEventsData({...EventsData,data:[...data]});


	 };
		
	return (
		<div className="mycontent events-content">
			
			<div className="inner">
				
				<div className="box">

					<h2>Users</h2>{<div className={"edit-btn "+(EventsData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>}

{EventsData.edit?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}

					
					{/*<div className="add-new-person-link-holder"><Link to="/events/add">Add Event</Link></div>
				*/}

				{RequestErrors.errors?renderErrors():null}
				
					<div className="form-blocks">
					<div className="form-block">

					{EventsData.addnew?<Show stored={newlist}/>:
				<table className="table-new fringetable has-links">
				
					<thead><tr><th>Name</th><th>Email</th><th>Password</th><th>Role</th>
					
					{EventsData.edit?
						<div className="edit-btn" onClick={(e) => {
							addNew();
   				 }}><span>+</span></div>:null}
					
					</tr></thead>
			
					<tbody>
						{rows}
					</tbody>
				
				
				
			</table>}</div></div></div>
		</div></div>		
	);
}
