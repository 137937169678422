import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";

import InputText from "./form/InputText.js";
import InputFile from "./form/InputFile.js";



import ShowTopNav from "./ShowTopNav.js";

import EventShowTopNav from "./EventShowTopNav.js";
import EventTopNav from "./EventTopNav.js";

import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultShowData={

	loaded:false,
	data:{texts:{}},
	orginal:null,
    edit:false
	
};

export const defaultFilesData={

	data:[]
	
};
export const defaultGroupData={

	loaded:false,
	data:[],
	orginal:[],
    edit:false
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function EventShowFiles(props) {
	const { state, dispatch } = useStore();
    const { show_id,event_id } = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });
	const [GroupData, setGroupData] = useState({
		...defaultGroupData
		
    });
	const [FilesData, setFilesData] = useState({
		...defaultFilesData
		
    });
	
	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	console.log("EVENT_ID",event_id)

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {


		console.log("FETCH",show_id);
       

		if(show_id){

//+"/workgroup/"+event_id

		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))
		}else{
		
			setShowData({...ShowData,loaded:true,edit:true});

		}
	  }, [show_id,event_id]);
	  useEffect(() => {


		console.log("FETCH",show_id);
       

		if(show_id){

//+"/workgroup/"+event_id

		fetch( config.apiUrl+"/shows/"+show_id+"/files/"+event_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setGroupData({data:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))
		}else{
		
			//setGroupData({...setGroupData,loaded:true,edit:true});

		}
	  }, [show_id,event_id]);



const changeEditMode=()=>{
	if(ShowData.edit){
		setShowData({...ShowData,data:{...ShowData.orginal},edit:!ShowData.edit})
		setGroupData({...GroupData,data:[...GroupData.orginal]});

		setFilesData({...FilesData,data:[]});
	}else{
	setShowData({...ShowData,edit:!ShowData.edit})
	}
};




const tryToSave=()=>{

let url="shows/"+show_id+"/files/"+event_id;

const formData = new FormData();

var files=[...FilesData.data];

for(var i=0;i<files.length;i++){

	formData.append(
		"filenew["+i+"]",
		(files[i].file?files[i].file:null)
	  );
	formData.append("datanew["+i+"][name]",files[i].name);
	formData.append("datanew["+i+"][description]",files[i].description?files[i].description:"");
	 
	
		
}
var olds=[...GroupData.data];
for(var i=0;i<olds.length;i++){
	formData.append("old["+i+"][id]",olds[i].id);
	formData.append("old["+i+"][name]",olds[i].name);
	formData.append("old["+i+"][description]",olds[i].description?olds[i].description:"");

}
apiCall.ADD(url, formData,function(status,err_msgs=null,v){
	if(status==400){
	 
	


	 
	
  }else if(status==200){
   
var data=v.data;

setFilesData({data:[]});
setGroupData({data:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true,edit:false});
setShowData({...ShowData,edit:false})

  }

}).then(() => {

  
});








return;
	let data={groups:[...GroupData.data]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			let data=v.data;
			setShowData({...ShowData,edit:false});
			setGroupData({data:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true,edit:false});
		//	if(show_id){
			//setShowData({...ShowData,"edit":false,data:{...data},orginal:JSON.parse(JSON.stringify((data)))});
			//setRequestErrors({errors:null});
			//apiCall.GET("users");
			/*}else{
				if(props.stored){
					
					props.stored(data);
				}
			}*/
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	/*console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});*/
};

console.log("COMMON",state.common.loading);
	if(!ShowData.loaded || !GroupData.loaded ){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
  
   

	console.log("SHOW",ShowData);
	const getInput=(label,name,textarea=false)=>{

		return <InputTextTranslations textarea={textarea} label={label} name={name}  onFocus={onFocus}  locales={state.common.locales}  onChange={changeInputValue} data={ShowData.data.texts} edit={ShowData.edit}/>
; 
	};
	console.log("DTEXTS",ShowData.data.texts);


	const locale_header=state.common.locales.map((locale)=>{
		return (<div className="td td-locale">{locale}</div>);
	});
	const remove=(index)=>{

		var data=[...GroupData.data];
        
       
        data=data.filter((value, curindex) => index !== curindex); 

      
        setGroupData({...GroupData,data:[...data]});


	};
	const removeNew=(index)=>{

		var data=[...FilesData.data];
        
       
        data=data.filter((value, curindex) => index !== curindex); 

      
        setFilesData({...FilesData,data:[...data]});


	};
	const addNew=()=>{
		var data=[{name:"",description:"",file:null,link:null},...FilesData.data];
		setFilesData({...FilesData,data:[...data]});

	};
	const changeFile=(name,e,index)=>{

		var data=[...FilesData.data];
		data[index].file=e.target.files[0];
		data[index].name=e.target.files[0].name
		console.log(e.target.files[0]);
		setFilesData({...FilesData,data:[...data]});
		
		
		
		/**/
	
	}
	const getEventTopic=()=>{

		if(event_id){
			return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
		}
		return null;
	}
const changeInputValueNew= (name,value,index) => {
	
 
	var data=[...FilesData.data];
	data[index][name]=value;

	setFilesData({...FilesData,data:[...data]});
};
	const changeInputValue = (name,value,index) => {
	
 
		var data=[...GroupData.data];
		data[index][name]=value;

		setGroupData({...GroupData,data:[...data]});
	};

	
	const rowsnew=FilesData.data.map((member,index) =>{

		return <tr key={index}>
<td>{member.file==null?<InputFile  onChange={changeFile}   edit={ShowData.edit} data={member} name="file" index={index}/>:null}</td>
			

			<td><InputText  onChange={changeInputValueNew}  edit={ShowData.edit} data={member} name="name" index={index}/></td>
			
			<td><InputText  onChange={changeInputValueNew}   edit={ShowData.edit} data={member} name="description" index={index}/></td>
			<td>-</td>
			<td>{ShowData.edit?<span className="remove" onClick={(e) => {
      				  removeNew(index);
   				 }}>Remove</span>:null}</td>
		
			{/*<td><Link to={"/shows/"+event.id}>{(event.showtimes.length)}</Link></td>*/}
		
		</tr>;
	  
	});
	const rows=GroupData.data.map((member,index) =>{

		return <tr key={index}>
<td></td>
			

			<td><InputText  onChange={changeInputValue}  onFocus={onFocus} edit={ShowData.edit} data={member} name="name" index={index}/></td>
			
			<td><InputText  onChange={changeInputValue}  onFocus={onFocus} edit={ShowData.edit} data={member} name="description" index={index}/></td>
			<td><a href={member.link} target="_blank">Download</a></td>

			<td>{ShowData.edit?<span className="remove" onClick={(e) => {
      				  remove(index);
   				 }}>Remove</span>:null}</td>
		
			{/*<td><Link to={"/shows/"+event.id}>{(event.showtimes.length)}</Link></td>*/}
		
		</tr>;
	  
	});


	console.log("ShowData",ShowData);
	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">
				<div className={(event_id!=null?"has-event":null)}>

				{getEventTopic()}

				<h2 >{(ShowData.data.texts && ShowData.data.texts.fi?ShowData.data.texts.fi.name:null)}</h2>

				{(show_id?<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>:null)}
	


					{ShowData.edit && show_id?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
					</div>
					
					<EventTopNav id={event_id} show_id={show_id}/>


					{show_id?<ShowTopNav  data={ShowData.data} errors={RequestErrors.errors} event_id={event_id} id={show_id}/>:null}

{/*show_id?<EventShowTopNav event_id={event_id} show_id={show_id}/>:null*/}
					

					<div className="form-blocks">
					<div className="form-block">
						
						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
						<table className="table-new fringetable has-links">
				
				<thead><tr><th></th><th>Filename</th><th>Description</th><th>Download</th><th>{ShowData.edit?<div className="edit-btn" onClick={(e) => {
							addNew();
   				 }}><span>+</span></div>:null}</th></tr></thead>
		
				<tbody>
					{rowsnew}
					{rows}
				</tbody>
			
			
			
		</table>
							
                        </div>
						{!show_id?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}





					</div>
				
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
