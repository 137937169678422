
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'

export default function CustomDate(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;
      
        props.onChange(name,value,props.index);
     
	};

    const errorkey=(props.useSimpleKey?props.name:"data."+props.name);


    const hasError=(props.errors && props.errors[errorkey]?true:false);
   //    console.log(hasError,hasError,hasError,props.errors,errorkey,"simple:",props.useSimpleKey);

    const onFocus=(e)=>{

        if(props.onFocus){
          let name = e.target.name;
          let value = e.target.value;
          props.onFocus(name,(value),props.index);
        
        }
    };



    //console.log(props.errors,"data."+props.name,hasError);
   /* const input=<input onFocus={(e) => {
        onFocus(e);
    }} autoComplete="off" name={props.name} value={props.data[props.name]===null?"":props.data[props.name]} 
    onChange={(e) => {
        changeInputValue(e);
    }} />;
*/


const handleDateChange=(v)=>{

    
    if(v==null){
        props.onChange(props.name,v);
    v=null;
    }else{
        v=format(v, 'yyyy-M-dd')
        props.onChange(props.name,v);
    }
   

};


const input=<DatePicker
selected={props.data[props.name]==null?"":new Date(props.data[props.name])}
onSelect={handleDateChange} //when day is clicked
onChange={handleDateChange} //only when value has changed
dateFormat="d.M.Y"
/>;



    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */

    
   
   const content=(props.edit===true?input:<span>{props.data[props.name]}</span>)
	return (<div className={"input"+(hasError?" error":"")}><label>{props.label}</label>{content}{hasError}
    {(hasError?<p className="error-text">{props.errors[errorkey]}</p>:null)}
    </div>);
}
