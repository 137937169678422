import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink,useLocation
	// etc.
  } from "react-router-dom";

export default function PersonTopNav(props) {
	const { state, dispatch } = useStore();

  
	const location = useLocation();

	
	if(!state.users.items){
		return null;
	}

	


	/**/ 
	const links=[
        {url:"/persons/"+props.id,name:"Perustiedot"},
		{url:"/persons/"+props.id+"/roles",name:"Roolit"},
        {url:"/persons/"+props.id+"/taxes",name:"Verotiedot"},
        {url:"/persons/"+props.id+"/settings",name:"Asetukset"},
        {url:"/persons/"+props.id+"/loan",name:"Yrityslaina"},
		//{url:"/persons/"+props.id+"/bonukset",name:"Bonukset"},
        ];

		const nav = links.map((item,index) =>
  		<li key={index} className={(location.pathname===item.url?"active-li":null)}><NavLink  activeClassName="active"  to={item.url} exact>{item.name}</NavLink></li>
		);

	
	
	return (
		<div className="leafnav">
              
            {(nav?<ul>{nav}</ul>:null)}
              
               {/*<ul>
					
                    <li><NavLink activeClassName="active" to="/persons">Työntekijät</NavLink></li>
                    <li><NavLink activeClassName="active" to="/palkanlaskenta">Palkanlaskenta</NavLink></li>
                    <li><NavLink activeClassName="active" to="/maksaminen">Maksaminen</NavLink></li>
                    <li><NavLink activeClassName="active" to="/asetukset">Asetukset</NavLink></li>
               </ul>*/}
				
			
					
					
			
			
		</div>
	);
}
