import React,{useState,useEffect} from "react";
import MedicLogo from '../icons/medic.svg';

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import AddPerson from "./AddPerson.js";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";


  export const defaultEventsData={

	loaded:false,
	
	data:[],
	
	
};


export default function Events() {
	const { state, dispatch } = useStore();

	const [EventsData, setEventsData] = useState({
		...defaultEventsData
		
    });

	useEffect(() => {
		fetch( config.apiUrl+"/events",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setEventsData({data:[...data],loaded:true}))

	  }, []);

	  console.log(EventsData.data);

		const rows=EventsData.data.map((event,index) =>{

			return <tr key={index}><td><Link to={"/events/"+event.id}>{event.name}</Link></td></tr>;
		  
		});
	return (
		<div className="mycontent events-content">
			
			<div className="inner">
				
				<div className="box">

					<h2>Events</h2>
					
					{/*<div className="add-new-person-link-holder"><Link to="/events/add">Add Event</Link></div>
				*/}
				
					<div className="form-blocks">
					<div className="form-block">
				<table className="table-new fringetable">
				
					<thead><tr><th>Name</th></tr></thead>
			
					<tbody>
						{rows}
					</tbody>
				
				
				
			</table></div></div></div>
		</div></div>		
	);
}
