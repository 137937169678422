import React from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";

export default function LoginForm() {
	const { state, dispatch } = useStore();

	const { user } = state;

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};

	const tryLogin = () => {
		let bodyFormData = new FormData();

		bodyFormData.set("email", user.email);
		bodyFormData.set("password", user.password);

		dispatch({ type: "LOGIN" });

		axios({
			url: config.apiUrl + "/auth/login",
			method: "post",
			data: bodyFormData
			
		}).then((response) => {

            console.log(response);
				if (response.status === 200 && response.data.access_token) {
					dispatch({ type: "LOGIN_SUCCESS", payload: response });
				} else {
					dispatch({ type: "LOGIN_FAIL", payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: "LOGIN_FAIL" });
			});
	};

	const createInput = (label, name) => {
		return (
			<label className="label-input">
				{label}
				<input
					type="text"
					name={name}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
				/>
			</label>
		);
	};
	const createPwd = (label, name) => {
		return (
			<label className="label-password">
				{label}
				<input
					type="password"
					name={name}
					value={user[name]}
					onChange={(e) => {
						changeInputValue(e);
					}}
				/>
			</label>
		);
	};

	return (
		<div className="loginContainer">
			<div className="loginForm">
				

				<form
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						tryLogin();
					}}
				>
					{createInput("Email", "email")}
					{createPwd("Password", "password")}
					{!user.loading && (
						<input
							type="submit"
							className="button primary"
							name="login"
							value="Login"
						/>
					)}
					
				</form>
			</div>
		</div>
	);
}
