import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import InputText from "./form/InputText";
import EventTopNav from "./EventTopNav.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import ShowTopNav from "./ShowTopNav.js";

import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultShowData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function ShowLinks() {
	const { state, dispatch } = useStore();
    const { show_id,event_id } = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	const remove=(index)=>{

		var data={...ShowData.data};
        var links=[...ShowData.data.links];
       
        links=links.filter((value, curindex) => index !== curindex); 

       
        setShowData({...ShowData,data:{...data,links:[...links]}});
	};
    const addLink=(target)=>{
        var data={...ShowData.data};
        var links=[...ShowData.data.links];
       
        data.links=[{description:"",url:"",type:{id:0} },
         ...links];
       
        setShowData({...ShowData,data:data});
  
      }
	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",show_id);
		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [show_id]);



const changeEditMode=()=>{


	setRequestErrors({errors:null});
    if(ShowData.edit){
		setShowData({...ShowData,data:{...ShowData.orginal},edit:!ShowData.edit})
	}else{
	setShowData({...ShowData,edit:!ShowData.edit})
	}

};




const tryToSave=()=>{
	setRequestErrors({errors:null});

let url="shows/"+show_id+"/links";
	let data={links:[...ShowData.data.links]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			let data=v.data;
			setShowData({...ShowData,data:{...data},"edit":false});
			setRequestErrors({errors:null});
			
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	
};

console.log("COMMON linktyps",state.common.linktypes);
	if(!ShowData.loaded ){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
  
    const changeInputValue=(name,value,index)=>{
        var data={...ShowData.data};
        var links=[...ShowData.data.links];
        links[index][name]=value;
        setShowData({...ShowData,data:{...data,links:[...links]}});





    };
	const changeLinkType=(n,v,i)=>{
		var data={...ShowData.data};
        var links=[...ShowData.data.links];
        links[i]["type"]["id"]=v;
        setShowData({...ShowData,data:{...data,links:[...links]}});

	};
	const linktypeOptions=state.common.linktypes.map((type,index) =>{
		return {id:type.id,name:type.type}
	});
	const getEventTopic=()=>{

		if(event_id){
			return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
		}
		return null;
	}
    const rows=ShowData.data.links.map((link,index) =>{

        return <tr key={index}>


<td>{ShowData.edit?<InputSelect edit={ShowData.edit}  zero={"Select link type"} index={index} onChange={changeLinkType} options={linktypeOptions} name="id" data={link.type}/>:link.type.type}
</td>
<td><InputText onFocus={onFocus}  index={index} errors={RequestErrors.errors}  onChange={changeInputValue} data={link} name="url" edit={ShowData.edit}/></td>
<td><InputText onFocus={onFocus}  index={index} errors={RequestErrors.errors}  onChange={changeInputValue} data={link} name="description" edit={ShowData.edit}/></td>

         

{ShowData.edit?<td><span class='remove-me' onClick={(e) => {
      				  remove(index);
   				 }}>Remove</span></td>:null}
</tr>;
      
    });
	console.log("SHOW",ShowData);
	



	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">
				<div className={(event_id!=null?"has-event":null)}>
				{getEventTopic()}
				<h2>{ShowData.data.texts.fi.name}</h2>

				{<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>}



					{ShowData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
</div>
					{event_id?<EventTopNav id={event_id} show_id={show_id}/>:null}
{show_id?<ShowTopNav  data={ShowData.data} errors={RequestErrors.errors} event_id={event_id} id={ShowData.data.id}/>:null}
		

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
                       


                        <table className="table-new fringetable"><thead><tr><th>Type</th><th>Link</th><th>Description</th><th>{ShowData.edit?
						<div className="edit-btn" onClick={(e) => {
      				 addLink();
   				 }}><span>+</span></div>:null}
</th></tr></thead><tbody>{rows}</tbody></table>
                        </div>
					


					</div>
					<div className="form-block">

						
					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
