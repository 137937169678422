


export default function InputProcent(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;
        
         props.onChange(name,value,props.index);
     
	};
    const input=<input name={props.name} value={props.data[props.name]} 
    onChange={(e) => {
        changeInputValue(e);
    }} />;
    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */
   const content=(props.edit==true?input:props.data[props.name])
   const label=(props.label && props.label!==""? <label>props.label</label>:null);
	return (<div className="input input-procent">{label}{content}</div>);
}
