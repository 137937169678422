const defaultState = {
	
	loading:true,
	
	firstLoad:false,
	mainLoader:true,
	locales:[],
	linktypes:[],
	categories:[]

};

export const commonInitialState = (() => {
	
	return defaultState;
})();

export const commonReducer = (state = commonInitialState, action) => {
	let companies = state;
	switch (action.type) {

		case "COMMON_SET_ORDER_YRITYSLAINAT":

			companies = {
				...state,
				sortIndexYritysLainat:action.sortIndexYritysLainat,
				sortIndexMode:(action.sortIndexMode?action.sortIndexMode:"asc")
			}
		break;
		case "COMMON_SET_ORDER":

		
		companies = {
			...state,
			sortIndexPersons:action.sortIndexPersons,
			sortIndexMode:(action.sortIndexMode?action.sortIndexMode:"asc")
		}
		break;
		case "COMMON_LOADED":

			
			companies = {
				...state,
				mainLoader:false,
				loading: false,
				
				locales:action.payload.data.locales,
				linktypes:action.payload.data.linktypes,
				categories:action.payload.data.categories


			};
			break;

		case "COMMON_LOADING":
			companies = {
				...state,
				
				loading: true,
				firstLoad:true
				
			};
			break;

		case "COMMON_FAIL":
			
			companies = {
				...state,
				
				loading: true,
				settings: null
			};
			break;
		

		default:
			companies = state;
		break;
    }
    return companies;
};