

import SettingTable from "./SettingTable.js";

export default function Settings(props) {

   
    const getSettingValues=(id)=>{

      return  props.settings.filter(function (item) {
            
            return item.usersettings_names_id ===id;

        });
    };

  
   
    const items = props.allSettings.map((item,index) =>

    
 
       <div className="setting-section" key={index}>
         <h5>{item.name}{props.edit?
						<div className="edit-btn" onClick={(e) => {
      				  props.addNewSettings(item.id);
   				 }}><span>+</span></div>:null}</h5>
         <SettingTable remove={(index)=>{

          props.remove(item.id,index);
         }} onChange=
           
           {(name,value,index) => {
            props.onChange(item.id,name,value,index);
        }}

        
           
           
          values={getSettingValues(item.id)} edit={props.edit} userajalla={item.userajalla} usedescription={item.usedescription}/>
       </div>
      


    );



	return (<div className="settings-all">{items}</div>);
}
