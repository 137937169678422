import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import InputBoolean from "./form/InputBoolean.js";

import EventTopNav from "./EventTopNav.js";
import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import ShowTopNav from "./ShowTopNav.js";

import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultShowData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};
export const defaultGenresData={

	loaded:false,
	data:[]
	
   
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function ShowSponsors() {
	const { state, dispatch } = useStore();
    const { show_id ,event_id} = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });
    const [GenresData, setGenresData] = useState({
		...defaultGenresData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",show_id);
		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [show_id]);

      useEffect(() => {
		console.log("FETCH GENRES");
		fetch( config.apiUrl+"/sponsors",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setGenresData({...GenresData,data:[...data],loaded:true}))

	  }, []);

const changeEditMode=()=>{
	if(ShowData.edit){
		setShowData({...ShowData,data:{...ShowData.orginal},edit:!ShowData.edit})
	}else{
	setShowData({...ShowData,edit:!ShowData.edit})
	}
};




const tryToSave=()=>{

let url="shows/"+show_id+"/sponsors";
	let data=[...ShowData.data.sponsors];
	
	apiCall.ADD(url, {sponsors:data},function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			
			setShowData({...ShowData,"edit":false,data:{...v.data},orginal:JSON.parse(JSON.stringify((v.data)))});
			setRequestErrors({errors:null});
			//apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};

console.log("COMMON",state.common.loading);
	if(!ShowData.loaded || !GenresData.loaded ){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
  
    const changeInputValue=(locale,name,value)=>{
		let texts={...ShowData.data.texts}
		texts[locale][name]=value;
		setShowData({...ShowData,data:{...ShowData.data,texts:texts}});




    };
    const onChangeGenre=(name,v,i)=>{
        let genres=ShowData.data.sponsors;
        let gid= GenresData.data[i].id;
       let selected=(genres.find(g => g.id ===gid)?true:false);
       
        if(selected){
            genres = genres.filter(g => g.id != gid);
            setShowData({...ShowData,data:{...ShowData.data,sponsors:[...genres]}});
        }else{
            setShowData({...ShowData,data:{...ShowData.data,sponsors:[...genres,{id:gid}]}});

        }
       
    };
	console.log("SHOW",ShowData);
	const getInput=(label,name,textarea=false)=>{

		return <InputTextTranslations textarea={textarea} label={label} name={name}  onFocus={onFocus}  locales={state.common.locales}  onChange={changeInputValue} data={ShowData.data.texts} edit={ShowData.edit}/>
; 
	};
	console.log("DTEXTS",ShowData.data.texts);


	
    const rows_genres=GenresData.data.map((genre,index)=>{

		return (ShowData.edit?
        (<div className="tr"><div className="td td-check"><InputBoolean index={index} onChange={onChangeGenre} name="selected" edit={true} data={{selected:(ShowData.data.sponsors.find(g => g.id === genre.id)?true:false)}} /></div><div className="td">{genre.name}</div></div>):(ShowData.data.sponsors.find(g => g.id === genre.id)?<div className="tr"><div className="td">{genre.name}</div></div>:null));
	});
	const getEventTopic=()=>{

		if(event_id){
			return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
		}
		return null;
	}
	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">
				<div className={(event_id!=null?"has-event":null)}>
				{getEventTopic()}
				<h2>{ShowData.data.texts.fi.name}</h2>

				{<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>}



					{ShowData.edit?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}
					
					</div>
					{event_id?<EventTopNav id={event_id} show_id={show_id}/>:null}
					{show_id?<ShowTopNav  data={ShowData.data} errors={RequestErrors.errors} event_id={event_id} id={ShowData.data.id}/>:null}
				

					

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
 							<div className="table-div">
								<div className="tr">
									<div className="td td-locale">Sponsor</div>
								</div>
								
                                {rows_genres}
							</div>
                       
                        </div>
						




					</div>
				
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
