import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import InputClock from "./form/InputClock.js";


import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";
import EventTopNav from "./EventTopNav.js";


import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


export const DefaultNewShowTime={

	data:[]
	
};
  
  export const defaultEventData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false,
	addnew:false
	
};
export const defaultPlacesData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};
export const DefaultRequestErrors={

	errors:null
}
export default function EventSchedule() {
	const { state, dispatch } = useStore();
    const { id } = useParams();
  
    const [EventData, setEventData] = useState({
		...defaultEventData
		
    });
	const [NewShowTime,setNewShowTime]=useState({

		...DefaultNewShowTime
	});
	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	
	const [PlacesData, setPlacesData] = useState({
		...defaultPlacesData
		
    });
	const apiCall = apiClient(state, dispatch);

	useEffect(() => {
		
		fetch( config.apiUrl+"/places",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setPlacesData({data:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, []);

    useEffect(() => {
		console.log("FETCH",id);
		fetch( config.apiUrl+"/events/"+id+"/infoschedule",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [id]);

const setNewShowTimeEdit=(n,v,index)=>{

	let data=[...NewShowTime.data];
	data[index]={...data[index],[n]:v};
	setNewShowTime({...NewShowTime,data:data})
}
const changeAddMode=()=>{
	//setEventData({...EventData,addnew:!EventData.addnew})
	let n={event_dates_id:null,
	show_id:null,
	place_id:null,
	time:"00:00",url:null}
	let data=[n,...NewShowTime.data]
	setNewShowTime({...NewShowTime,data:data})


};
const changeEditMode=()=>{
	//setEventData({...EventData,edit:!EventData.edit})

	setRequestErrors({errors:null});
	if(EventData.edit){
		setEventData({...EventData,data:{...EventData.orginal},edit:!EventData.edit})
	}else{
		setEventData({...EventData,edit:!EventData.edit})
	}



};
const changeUrl=(n,v,index,date_index)=>{

	let data={...EventData.data};
	let old={...data.dates[date_index].showtimes[index]};
	old.url=v;
	
	data.dates[date_index].showtimes[index]=old;
	//data.dates[date_index].showtimes=data.dates[date_index].showtimes.filter((value, curindex) => index !== curindex); 

	//const dateto = data.dates.find(element => element.id == v);

	//dateto.showtimes=[old,...dateto.showtimes]

	
	setEventData({...EventData,data:data})


	
};
const changeClock=(n,v,index,date_index)=>{


	let data={...EventData.data};
	let old={...data.dates[date_index].showtimes[index]};
	old.time=v;
	
	data.dates[date_index].showtimes[index]=old;
	//data.dates[date_index].showtimes=data.dates[date_index].showtimes.filter((value, curindex) => index !== curindex); 

	//const dateto = data.dates.find(element => element.id == v);

	//dateto.showtimes=[old,...dateto.showtimes]

	
	setEventData({...EventData,data:data})
	//let showtimes=[...ShowData.data.showtimes];
	//showtimes[index]={...alershowtimes[index],time:v};

	//setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})
};



const tryToSave=()=>{

let url="events/"+id+"/schedule"
	let data={dates:[...EventData.data.dates],newshows:[...NewShowTime.data]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			//setUserData({...UserData,"edit":false});
			let data=v.data;
			setRequestErrors({errors:null});
			setEventData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true,edit:false});
			//apiCall.GET("users");
			setNewShowTime({...NewShowTime,data:[]})
		}

	}).then(() => {

		//apiCall.GET('destinations');
		
	});
   




	
};
const onFocus=(name,value,index)=>{

};
const genres=(genres)=>{

return genres.map((genre,index) =>{
	return (<span>{genre.texts.fi.name}</span>)
});

}

const showtimes=(showtimes)=>{

	return showtimes.map((showtime,index) =>{
		return (<span>{showtime.date} {showtime.time} {showtime.place.texts.fi.name}</span>)
	});
	
	}


console.log(EventData);

	if(!EventData.loaded || !PlacesData.loaded){

		return (<div>Loading</div>);
	}
	console.log("ED",EventData);

	const changeShow=(n,v,index,date_index)=>{
		

		let data={...EventData.data};
		let old={...data.dates[date_index].showtimes[index]};
		old.show_id=v;
		old.show.id=v;
		data.dates[date_index].showtimes[index]=old;
	
	//	data.dates[date_index].showtimes[index]=old;
		//data.dates[date_index].showtimes=data.dates[date_index].showtimes.filter((value, curindex) => index !== curindex); 

		//const dateto = data.dates.find(element => element.id == v);

		//dateto.showtimes=[old,...dateto.showtimes]

	//	console.log("STORE",data);
		setEventData({...EventData,data:data})


		//let showtimes=[...ShowData.data.showtimes];
		//showtimes[index]={...showtimes[index],place_id:v,place:{...showtimes[index].place,id:v}};
		//setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})


	};
	const changePlace=(n,v,index,date_index)=>{
		

		let data={...EventData.data};
		let old={...data.dates[date_index].showtimes[index]};
		old.place_id=v;
		old.place.id=v;
		data.dates[date_index].showtimes[index]=old;
		//data.dates[date_index].showtimes=data.dates[date_index].showtimes.filter((value, curindex) => index !== curindex); 

		//const dateto = data.dates.find(element => element.id == v);

		//dateto.showtimes=[old,...dateto.showtimes]

		console.log("STORE",data);
		setEventData({...EventData,data:data})


		//let showtimes=[...ShowData.data.showtimes];
		//showtimes[index]={...showtimes[index],place_id:v,place:{...showtimes[index].place,id:v}};
		//setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})


	};
	
	const showOptions=EventData.data.shows.map((place,index)=>{



		return {id:place.id,name:place.texts.fi.name}
		
	});
	const placesOptions=PlacesData.data.map((place,index)=>{


let name=place.texts.fi.name;
if(place.texts.fi.subname){
	name+=" - "+place.texts.fi.subname;
}
		return {id:place.id,name:name}
		
	});
	const changeDate=(n,v,index,date_index)=>{

		//let showtimes=[...ShowData.data.showtimes];
		//showtimes[index]={...showtimes[index],event_dates_id:v};
		//setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})
		let data={...EventData.data};
		let old={...data.dates[date_index].showtimes[index]};
		old.event_dates_id=v;
		data.dates[date_index].showtimes=data.dates[date_index].showtimes.filter((value, curindex) => index !== curindex); 


		
		const dateto = data.dates.find(element => element.id == v);

		dateto.showtimes=[old,...dateto.showtimes]

		console.log("STORE",data);
		setEventData({...EventData,data:data})
		//var old={...data.dates[date_index].show_times[index]};
		//console.log(old);

//	setEventData({...EventData,data:!EventData.edit})

	};
	

	const eventDatesOptions= EventData.data.dates.map((d,index)=>{
				return {id:d.id,name:d.date_str}
				
			});
		
		
const removeNew=(index)=>{


/*let n={event_dates_id:null,
	show_id:null,
	place_id:null,
	time:"00:00"}
	let data=[n,...NewShowTime.data]
	setNewShowTime({...NewShowTime,data:data})*/ 
		var data=[...NewShowTime.data];
        
       
        data=data.filter((value, curindex) => index !== curindex); 

      
        setNewShowTime({...NewShowTime,data:[...data]});

		

};
const removeOld=(date_index,index)=>{

	let data={...EventData.data};
		let oldshows=[...data.dates[date_index].showtimes];

		oldshows=oldshows.filter((value, curindex) => index !== curindex); 

		data.dates[date_index].showtimes=oldshows;
		console.log(data.dates);
		setEventData({...EventData,data:data})
};
	
const newrow=(data,index)=>{

return <tr>

<td><InputSelect zero={"valitse"} edit={true}  onChange={setNewShowTimeEdit} options={eventDatesOptions} index={index} name="event_dates_id" data={data}/></td>
<td><InputSelect zero={"valitse"} edit={true}  onChange={setNewShowTimeEdit} options={showOptions}  index={index} name="show_id" data={data}/></td>
					
<td><InputClock onChange={setNewShowTimeEdit} name="time" data={data}  index={index} /></td>

<td><InputSelect zero={"valitse"} edit={true}  onChange={setNewShowTimeEdit} options={placesOptions}  index={index} name="place_id" data={data}/></td>
<td><InputText onChange={setNewShowTimeEdit}  edit={true}  name="url" data={data}  index={index} /></td>


<td><span className="remove" onClick={(e) => {
      				  removeNew(index);
   				 }}>Remove</span></td>

</tr>
	}

	const newrows=NewShowTime.data.map((data,index) =>{

		return newrow(data,index);

		/*return <tr key={index}>
			<td><Link to={"/events/"+id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;*/
	  
	});
	

	const mapShowTimes=(showtimes,date,date_index)=>{



		if(EventData.edit){

		
console.log("SHOW_TIMES to be mapped...",showtimes);
			
			
			return showtimes.map((showtime,index) =>{
				return <tr>
					<td className="s-select"><InputSelect edit={true} index={index} onChange={(n,v,index) => {
      				  changeDate(n,v,index,date_index);
   				 }} options={eventDatesOptions} name="event_dates_id" data={showtime}/></td>
					<td  className="s-select"><InputSelect zero={"valitse"} edit={true} index={index} onChange={(n,v,index) => {
      				  changeShow(n,v,index,date_index);
   				 }} options={showOptions} name="id" data={showtime.show}/></td>
					
					<td><InputClock onChange={(n,v,index) => {
      				  changeClock(n,v,index,date_index);
   				 }} name="time" data={showtime} index={index}/></td>
					<td className="s-select"><InputSelect zero={"valitse"} edit={true} index={index} onChange={(n,v,index) => {
      				  changePlace(n,v,index,date_index);
   				 }} options={placesOptions} name="id" data={showtime.place}/></td>

					<td className="urlini"><InputText onChange={(n,v,index) => {
      				  changeUrl(n,v,index,date_index);
   				 }}
					
					
					
					edit={true}  name="url" data={showtime}  index={index} /></td>

					<td><span className="remove" onClick={(e) => {
      				  removeOld(date_index,index);
   				 }}>Remove</span></td>
					</tr>
			});


//n,v,index,date_index


		}
		console.log("SHOW_TIMES to be mapped first...",showtimes);
		return showtimes.map((showtime,index) =>{
			return <tr>
				<td>{date}</td>
				<td><Link to={"/events/"+id+"/shows/"+showtime.show.id}>{showtime.show.texts.en.name}</Link></td>
				
				<td><Link to={"/events/"+id+"/shows/"+showtime.show.id}>{showtime.time}</Link></td>
				<td><Link to={"/events/"+id+"/shows/"+showtime.show.id}>{showtime.place.texts.en.name}</Link></td>
				<td><Link to={"/events/"+id+"/shows/"+showtime.show.id}>{showtime.url}</Link></td>
				</tr>
		});






	};
	const rows=EventData.data.dates.map((date,index) =>{

		return mapShowTimes(date.showtimes,date.date_str,index);

		/*return <tr key={index}>
			<td><Link to={"/events/"+id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;*/
	  
	});


	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				<h2>{EventData.data.name}</h2>
				{EventData.edit?
				<div className={"edit-btn "+(EventData.addnew?"active":"")} onClick={(e) => {
      				  changeAddMode(e);
   				 }}><span>Add new</span></div>:null}


<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>

				<EventTopNav errors={RequestErrors.errors} id={EventData.data.id}/>

				{/*<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>*/}

					

					

					<div className="form-blocks">
					<div className="form-block">

						
						
						<table className="table-new fringetable bgtable">
						<thead><tr>
							<th>PVM</th>
							<th>Nimi</th>
							<th>Aika</th>
							<th>Paikka</th>
							<th>Url</th>
							<th></th></tr></thead>

							<tbody>
								
								{newrows}
								{rows}</tbody>
						</table>
						{EventData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					</div>
					<div className="form-block">

						
					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
