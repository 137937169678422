import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import EventTopNav from "./EventTopNav.js";
import ShowTopNav from "./ShowTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";
import InputBoolean from "./form/InputBoolean.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultShowData={

	loaded:false,
	data:{texts:{}},
	orginal:null,
    edit:false
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function Show(props) {
	const { state, dispatch } = useStore();
    const { show_id,event_id } = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	console.log("EVENT_ID",event_id)

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {


		console.log("FETCH",show_id);

		if(show_id){
		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))
		}else{
		
			setShowData({...ShowData,loaded:true,edit:true});

		}
	  }, [show_id]);



const changeEditMode=()=>{

	setRequestErrors({errors:null});
	if(ShowData.edit){
		setShowData({...ShowData,data:{...ShowData.orginal},edit:!ShowData.edit})
	}else{
	setShowData({...ShowData,edit:!ShowData.edit})
	}
};




const tryToSave=()=>{


	setRequestErrors({errors:null});

let url="shows";
if(show_id){
	url+="/"+show_id;
}
	let data={...ShowData.data};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			let data=v.data;
			if(show_id){
			setShowData({...ShowData,"edit":false,data:{...data},orginal:JSON.parse(JSON.stringify((data)))});
			//setRequestErrors({errors:null});
			//apiCall.GET("users");
			}else{
				if(props.stored){
					
					props.stored(data);
				}
			}
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	
};

console.log("COMMON",state.common.loading);
	if(!ShowData.loaded ){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
	const onChangeApproved=(name,value)=>{
		var v=1;
		if(parseInt(ShowData.data.approved)==1){

		v=0;

		}
		
		setShowData({...ShowData,data:{...ShowData.data,approved:v}});



	};
    const changeInputValue=(locale,name,value)=>{
		let texts={...ShowData.data.texts}
		if(!texts[locale]){
			texts[locale]={};
		}
		texts[locale][name]=value;
		setShowData({...ShowData,data:{...ShowData.data,texts:texts}});




    };

	console.log("SHOW",ShowData);
	const getInput=(label,name,textarea=false,classes=null)=>{

		return <InputTextTranslations className={classes} textarea={textarea} label={label} name={name}  onFocus={onFocus}  locales={state.common.locales}  onChange={changeInputValue} data={ShowData.data.texts} edit={ShowData.edit}/>
; 
	};
	console.log("DTEXTS",ShowData.data.texts);


	const locale_header=state.common.locales.map((locale)=>{
		return (<div className="td td-locale">{locale}</div>);
	});
	const getEventTopic=()=>{

		if(event_id){
			return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
		}
		return null;
	}

	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				<div className={(event_id!=null?"has-event":null)}>
				{getEventTopic()}

				<h2>{(ShowData.data.texts && ShowData.data.texts.fi?ShowData.data.texts.fi.name:null)}</h2>

				{(show_id?<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>:null)}

				

					{ShowData.edit && show_id?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


					</div>

{event_id?<EventTopNav id={event_id} show_id={show_id}/>:null}
{show_id?<ShowTopNav  data={ShowData.data} errors={RequestErrors.errors} event_id={event_id} id={ShowData.data.id}/>:null}
					

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
 							<div className="table-div">
								<div className="tr">
									<div className="td td-locale td-empty"></div>{locale_header}
								</div>
								<InputTextTranslations onFocus={onFocus}  locales={state.common.locales} label="Name *" onChange={changeInputValue} data={ShowData.data.texts} name="name" edit={ShowData.edit}/>
								{getInput("Group name","group_name")}
								
								{getInput("Country","country")}
								{getInput("Language","language")}
								{getInput("Duration","duration")}
								{getInput("Suitability","suitability")}
								{getInput("Short description","short_description",true,"smaller")}
								{getInput("Description","description",true,"smaller")}
								{ShowData.data.is_hakemus==1?
								<div class='tr'><div class='th td'>Application approved</div><div class='td'>


									{ShowData.edit?<InputBoolean onChange={onChangeApproved} name="approved" edit={true} data={{approved:(ShowData.data.approved==1?true:false)}} />:
									(ShowData.data.approved==1?"Yes":"No")}</div></div>
									

								
								:null}
							
							
							
							</div>
							

							
							
                        </div>
						{!show_id?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}





					</div>
				
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
