const defaultState = {
	loggedIn: false,
	password: "",
	email:  "",
	loading: false,
	token: null,
	
};


const localStorageKey = "user_local";


export const userInitialState = (() => {
	
	let state = window.localStorage.getItem(localStorageKey)
		? JSON.parse(window.localStorage.getItem(localStorageKey))
		: defaultState;
console.log(state);
		return state;
})();

export const userReducer = (state = userInitialState, action) => {
	let user = state;
	switch (action.type) {
		case "LOGINFORM_INPUT":
			var ns = { ...state, [action.key]: action.value };

			user = ns;
			break;

		case "LOGIN":
			user = {
				...state,
				loggedIn: false,
				loading: true,
				token: null,
			};
			break;
		case "LOGIN_SUCCESS":
			user = {
				...state,
				loggedIn: true,
				loading: false,
				token: action.payload.data.access_token,
				role:action.payload.data.role
			};
			break;

		case "LOGIN_FAIL":
			
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
			};
			break;
		case "LOGOUT":

	
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
				role:null
			};
		
			window.localStorage.setItem(localStorageKey, JSON.stringify(user));

			break;

		default:
			user = state;
		break;
    }
	window.localStorage.setItem(localStorageKey, JSON.stringify(user));

    return user;
};