import React,{useState,useEffect} from "react";
import MedicLogo from '../icons/medic.svg';

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Show from "./Show.js";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";


  export const defaultEventsData={

	loaded:false,
	addnew:false,
	data:[],
	
	
};


export default function Applications() {
	const { state, dispatch } = useStore();

	const [EventsData, setEventsData] = useState({
		...defaultEventsData
		
    });

	useEffect(() => {
		fetch( config.apiUrl+"/applications",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setEventsData({data:[...data],loaded:true}))

	  }, []);
	  const getGenres=(genres)=>{

			return genres.map((g,index) =>{

				return <span>{g.texts.fi.name}{(index+1!=genres.length)?", ":null}</span>
			});

	  };
	  const newlist=(d)=>{

		setEventsData({...EventsData,data:[...d],addnew:false})
	  }
	 
	  console.log(EventsData.data);
 
		const rows=EventsData.data.map((event,index) =>{

			return <tr key={index}>
				<td><Link to={"/shows/"+event.id}>{event.texts.fi.name}</Link></td>
				<td><Link to={"/shows/"+event.id}>{getGenres(event.genres)}</Link></td>

				<td><Link to={"/shows/"+event.id}>{(event.performers.length)}</Link></td>
				<td><Link to={"/shows/"+event.id}>{(event.images.length)}</Link></td>
				{/*<td><Link to={"/shows/"+event.id}>{(event.showtimes.length)}</Link></td>*/}
			
			</tr>;
		  
		});

		const changeAddMode=()=>{

	
			setEventsData({...EventsData,addnew:!EventsData.addnew})
	
	 };
		
	return (
		<div className="mycontent events-content">
			
			<div className="inner">
				
				<div className="box">

					<h2>Applications</h2>
					
					{/*<div className="add-new-person-link-holder"><Link to="/events/add">Add Event</Link></div>
				*/}
				
					<div className="form-blocks">
					<div className="form-block">

					{EventsData.addnew?<Show stored={newlist}/>:
				<table className="table-new fringetable has-links">
				
					<thead><tr><th>Name</th><th>Genres</th><th>Performers</th><th>Images</th></tr></thead>
			
					<tbody>
						{rows}
					</tbody>
				
				
				
			</table>}</div></div></div>
		</div></div>		
	);
}
