import React,{createRef} from "react";
import Picker from 'react-month-picker';
export default function Period(props) {

   

    const pickerLang = {
        months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        from: 'From', to: 'To',
    }
    //const season={year: 2021, month: 4};
   
    let date="";

   
   if(!props.value){

        return null;
   }
    let dates=props.value.split("-");
   
    if(dates.length===3){
        date=dates[1]+"/"+dates[0];
    }

    

   
    const season={year: parseInt(dates[0]), month: parseInt(dates[1])};
  
    const pickAMonth = createRef();
    const openSelector=()=>{

        pickAMonth.current.show();

    };
    const changeValue=(y,m)=>{
        m+="";
       if(m.length==1){
           m="0"+m;
       }
        var v=y+"-"+m+"-"+"01";
      

       props.onChange(props.name,v,props.index);
       pickAMonth.current.dismiss();

    };
    /*
   
                          
                       <div onClick={openSelector}>{makeText(season)}</div>
                       */ 
let min={year: 2000, month: 1};

console.log("DATES_MIN",props.min);
            if(props.min){



                let dates_min=props.min.split("-");
   
                if(dates_min.length===3){
                  min={year: parseInt(dates_min[0]), month: parseInt(dates_min[1])};
                    //date=dates[1]+"/"+dates[0];

                    console.log("DATES_MIN",dates_min[0],dates_min[1]);
                }
               




            }
            let max={year: 2030, month: 12};
            if(props.max){


                let dates_max=props.max.split("-");
   
                if(dates_max.length===3){
                  max={year: parseInt(dates_max[0]), month: parseInt(dates_max[1])};
                    //date=dates[1]+"/"+dates[0];

                    console.log("DATES_MAX",max);
                }




            }
	return (
        <div>
     <Picker value={season}  years={{min: min, max: max}} onChange={changeValue} lang={pickerLang.months} ref={pickAMonth} />

     
    <div className="period" 
    
    onClick={(e) => {
        
        e.preventDefault();
        e.stopPropagation();
        //tryLogin();
        if(props.edit){
            openSelector();
        }
    }}
    
    >{date}</div></div>);
}
