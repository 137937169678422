import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import InputBoolean from "./form/InputBoolean.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";
import EventTopNav from "./EventTopNav.js";


import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultEventData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false,
	datetoadd:null
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function Event() {
	const { state, dispatch } = useStore();
    const { id } = useParams();
  
    const [EventData, setEventData] = useState({
		...defaultEventData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",id);
		fetch( config.apiUrl+"/events/"+id+"/infodatesandname",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [id]);



const changeEditMode=()=>{
	if(EventData.edit){
		setEventData({...EventData,data:{...EventData.orginal},edit:!EventData.edit})
	}else{
		setEventData({...EventData,edit:!EventData.edit})
	}

};




const tryToSave=()=>{

let url="events/"+id;
	let data={...EventData.data};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
		
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
		
			let data=v.data;
			
			setEventData({...EventData,data:{...data},edit:false,orginal:JSON.parse(JSON.stringify((data)))})
			//setRequestErrors({errors:null});
			//apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};




console.log(EventData);

	if(!EventData.loaded){

		return (<div></div>);
	}
	console.log("ED",EventData);

	const addNewDate=(e)=>{
		let data={...EventData.data};
		data.dates=[...EventData.data.dates,{date:EventData.datetoadd}];
		setEventData({...EventData,data:{...data},datetoadd:null})
		
	}
	const removeDate=(index)=>{
		if(EventData.edit){


			let data={...EventData.data};
			/*  var links=[...ShowData.data.links];
       
        			links=links.filter((value, curindex) => index !== curindex); */
					let dates=[...EventData.data.dates];

					dates=dates.filter((value, curindex) => index !== curindex);

				data.dates=dates;
				setEventData({...EventData,data:{...data}});
		}
	}

	const changeInputValue = (name,value) => {
	
		if(name==="datetoadd"){
			if(value && value.split("-").length==3){
			setEventData({...EventData,[name]:value});
			}
		}else{
			let data={...EventData.data,[name]:value}
			setEventData({...EventData,data:{...data}});
		}
 
	}
	console.log("DATESIT",EventData.data.dates)
	const dates=EventData.data.dates.map((date,index) =>{

		return <span onClick={(e) => {removeDate(index)}} key={index}>{date.date} </span>;
	  
	});
	const onChangeCall=(name,value)=>{


		let data={...EventData.data,[name]:value}
		if(value==0){
			data.artist_call_valid=null;
		}
		setEventData({...EventData,data:{...data}});


	};
	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				<h2>{EventData.data.name}</h2>

				{<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>}

					{EventData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}


				<EventTopNav id={EventData.data.id}/>


					

					<div className="form-blocks">
					<div className="form-block">

					<InputText label="Name of the event" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="name" edit={EventData.edit}/>
					</div>

					<div className="form-block">
					<div className={!EventData.edit?"input checkki noedit":"input checkki"} >
<label>Artist call</label>
{EventData.edit?"":(EventData.data.artist_call_open===1?"Open,Closes at: "+EventData.data.artist_call_valid:"Not open")}

{EventData.edit?<InputBoolean  onChange={onChangeCall} name="artist_call_open" edit={true} data={EventData.data} />
:null}

{EventData.edit && EventData.data.artist_call_open===1?	<CustomDate useSimpleKey={true}  label="Closes" name="artist_call_valid" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} edit={true}/>
:null}

</div>




					</div>



					{EventData.data.artist_call_open? <div class="form-block">

					<InputText label="Topic for artist call (fi)" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="topic_fi" edit={EventData.edit}/>
					<InputText label="Topic for artist call (en)" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="topic_en" edit={EventData.edit}/>
					<InputText label="Topic for artist call (sv)" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="topic_sv" edit={EventData.edit}/>

					</div>:null}
					{EventData.data.artist_call_open? <div class="form-block full">

					<InputText  type="textarea" label="Intro for artist call (fi)" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="intro_fi" edit={EventData.edit}/>
					<InputText type="textarea" label="Intro for artist call (en)" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="intro_en" edit={EventData.edit}/>
					<InputText type="textarea" label="Intro for artist call (sv)" onChange={changeInputValue} data={EventData.data} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="intro_sv" edit={EventData.edit}/>

					</div>:null}

				 <div class="form-block topic"><div class="input"><label>Event dates</label></div></div>








					
					{EventData.edit?<div  className="form-block">
							<CustomDate useSimpleKey={true}  label="Päivämäärä" name="datetoadd" onChange={changeInputValue} data={EventData} onFocus={onFocus} errors={RequestErrors.errors} edit={true}/>
							<span onClick={addNewDate}>+</span>
					</div>:null}
					<div  className={EventData.edit?"form-block event-dates edit ":"form-block event-dates "}>{dates}</div>
					
					</div>
					<div className="form-block">

						
					</div>
					
					

					
				</div>
					
			</div>
			
			
		</div>
	);
}
