


export default function InputTextTranslations(props) {

    const changeInputValue = (locale,e) => {
		let name = e.target.name;
		let value = e.target.value;
       

         props.onChange(locale,name,value,props.index);
     
	};
    const onBlur=(locale,e)=>{

       /* if(props.onBlur){
          let name = e.target.name;
          let value = e.target.value;
          //props.onBlur(name,(value),props.index,props.targetkey);
        
        }*/
     };
    const errorkey=(props.useSimpleKey?props.name:"data."+props.name);


    const hasError=(props.errors && props.errors[errorkey]?true:false);


    const onFocus=(locale,e)=>{

     /*   if(props.onFocus){
          let name = e.target.name;
          let value = e.target.value;
          props.onFocus(name,(value),props.index);
        
        }*/
    };



    //console.log(props.errors,"data."+props.name,hasError);
   /* const input=<input onFocus={(e) => {
        onFocus(e);
    }} autoComplete="off" name={props.name} value={props.data[props.name]===null?"":props.data[props.name]} 
    
    
    
    
    
    
    onChange={(e) => {
        changeInputValue(e);
    }} 
    onBlur={(e) => {
        onBlur(e);
    }} 
    />;
    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */
   // console.log("pdaata",props.data);

    const getFields=()=>{

       
        const v=props.locales.map((locale)=>{

            return <div className={"td"+(props.className?" "+props.className:"")} >
                {props.showlang?<div className="th-inner">{locale}</div>:null}
                <div className="td-inner">
                    {props.edit?
                    <div className="input">
{props.textarea?
    <textarea onFocus={(e) => {
        onFocus(locale,e);
    }} autoComplete="off" locale={locale} name={props.name} 

    onChange={(e) => {
        changeInputValue(locale,e);
    }} 
    onBlur={(e) => {
        onBlur(locale,e);
    }} 
    >{(props.data && props.data[locale] && props.data[locale][props.name]!==null?props.data[locale][props.name]:"")}</textarea>
:<input onFocus={(e) => {
        onFocus(locale,e);
    }} autoComplete="off" locale={locale} name={props.name} value={(props.data && props.data[locale] && props.data[locale][props.name]!==null?props.data[locale][props.name]:"")}

    onChange={(e) => {
        changeInputValue(locale,e);
    }} 
    onBlur={(e) => {
        onBlur(locale,e);
    }} 
    />}







                    </div>:
                    (props.data && props.data[locale] && props.data[locale][props.name]!==null?props.data[locale][props.name]:"")
                    }
                    
                </div> 
             </div>;

        });
        return v;
    };
    const fields=getFields();

    return (<div className="tr">{props.label?<div className="td th">{props.label}</div>:null}{fields}{props.remove?<div class='td remove-me' onClick={(e) => {
        props.remove(props.index);
    }}>Poista</div>:null}</div>);
   /*
   const content=(props.edit===true?input:<span>{props.data[props.name]}</span>)
	return (<div className={"input"+(hasError?" error":"")}><label>{props.label}</label>{content}{hasError}
    {(hasError?<p className="error-text">{props.errors[errorkey]}</p>:null)}
    </div>);*/
}
