import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink,useLocation
	// etc.
  } from "react-router-dom";

export default function ShowTopNav(props) {
	const { state, dispatch } = useStore();

  
	const location = useLocation();

	
	const renderErrors=()=>{

		console.log("orororo",props.errors,Object.keys(props.errors).length)

		let errors=Object.keys(props.errors).map(function(key) {
			
			return props.errors[key].map(function(v,index) {
				return  <div className="error">{v}</div>
			});

		});

		return <div className="errors">{errors}</div>



	}

	


	/**/ 
	const URL_START=(props.event_id!=null?"/events/"+props.event_id:"");
	let links=[
        {url:URL_START+"/shows/"+props.id,name:"Basic info"},
		
		
        ];

		

		if(props.data && props.data.is_hakemus){
			links.push( {url:URL_START+"/shows/"+props.id+"/application",name:"Application info"});
		}
		const links2=[
			
			{url:URL_START+"/shows/"+props.id+"/genres",name:"Genres"},
			{url:URL_START+"/shows/"+props.id+"/performers",name:"Performers"},
		   {url:URL_START+"/shows/"+props.id+"/otherpersons",name:"Other persons"},
		   {url:URL_START+"/shows/"+props.id+"/images",name:"Images"},
		   {url:URL_START+"/shows/"+props.id+"/sponsors",name:"Sponsors"},
	
		   
			{url:URL_START+"/shows/"+props.id+"/links",name:"Links"}
			
			
			];

			if(props.event_id==null){
				links2.push({url:URL_START+"/shows/"+props.id+"/events",name:"Events"});


			}else{

				if(state.user.role==1){
					links2.push(  {url:URL_START+"/shows/"+props.id+"/workgroup",name:"Work group"});
					links2.push({url:URL_START+"/shows/"+props.id+"/files",name:"Files"});
				}

			}

		const nav_left = links.map((item,index) =>
  		<li key={index+"l"} className={(location.pathname===item.url?"active-li":null)}><NavLink  activeClassName="active"  to={item.url} exact>{item.name}</NavLink></li>
		);
		const nav_right = links2.map((item,index) =>
		<li key={index} className={(location.pathname===item.url?"active-li":null)}><NavLink  activeClassName="active"  to={item.url} exact>{item.name}</NavLink></li>
	  );
	
		
	return (
		<div className="leafnav-outer">
		<div className={(props.event_id==null?"leafnav ":"leafnav as-event-menu")}>
             
            {(nav_left?<ul>{nav_left}{nav_right}</ul>:null)}
              
               {/*<ul>
					
                    <li><NavLink activeClassName="active" to="/persons">Työntekijät</NavLink></li>
                    <li><NavLink activeClassName="active" to="/palkanlaskenta">Palkanlaskenta</NavLink></li>
                    <li><NavLink activeClassName="active" to="/maksaminen">Maksaminen</NavLink></li>
                    <li><NavLink activeClassName="active" to="/asetukset">Asetukset</NavLink></li>
               </ul>*/}
				
			
					
					
			
			
		</div>
			{props.errors?renderErrors():null}
		</div>
	);
}
