import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import  {ReactComponent as ICON_ASETUKSET} from '../icons/asetukset.svg';
import  {ReactComponent as ICON_MAKSAMINEN} from '../icons/maksaminen.svg';
import  {ReactComponent as ICON_PALKANLASKENTA} from '../icons/palkanlaskenta.svg';

import  {ReactComponent as ICON_TYONTEKIJAT} from '../icons/tyontekijat.svg';


import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export default function Persons() {
	const { state, dispatch } = useStore();

  


	
	if(!state.user.loggedIn){
		return null;
	}



	/**/ 

	

	return (
		<div className="sidenav">
               
            
                <h1>S</h1>
               <ul>

                    <li><NavLink activeClassName="active" to="/events"><ICON_TYONTEKIJAT />Events</NavLink></li>

					<li><NavLink activeClassName="active" to="/shows"><ICON_TYONTEKIJAT />Shows</NavLink></li>
					<li><NavLink activeClassName="active" to="/applications"><ICON_TYONTEKIJAT />Applications</NavLink></li>

					{state.user.role!=3?
					<li><NavLink activeClassName="active" to="/genres"><ICON_TYONTEKIJAT />Genres</NavLink></li>
					:null}
						{state.user.role!=3?<li><NavLink activeClassName="active" to="/places"><ICON_TYONTEKIJAT />Places</NavLink></li>:null}
						{state.user.role!=3?<li><NavLink activeClassName="active" to="/sponsors"><ICON_TYONTEKIJAT />Sponsors</NavLink></li>:null}
						{state.user.role!=3?<li><NavLink activeClassName="active" to="/categories"><ICON_TYONTEKIJAT />Categories</NavLink></li>:null}
					

					{state.user.role===1?<li><NavLink activeClassName="active" to="/users"><ICON_TYONTEKIJAT />Users</NavLink></li>:null}

					<li><span onClick={(e)=>{

						dispatch({type:"LOGOUT"});

					}} to="/">Logout</span></li>

					<li className="ver">v 2.0b</li>
                   
               </ul>
				 
				
					
					
			
			
		</div>
	);
}
