import React,{useState,useEffect, memo} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";
import EventTopNav from "./EventTopNav.js";


import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultEventData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false,
	newmemos:[]
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function EventMemos() {
	const { state, dispatch } = useStore();
    const { id } = useParams();
  
    const [EventData, setEventData] = useState({
		...defaultEventData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	const addNew=()=>{

		let memos=[{content:""},...EventData.newmemos];
		setEventData({...EventData,newmemos:memos});

	}

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",id);
		fetch( config.apiUrl+"/events/"+id+"/memos",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventData({data:{...data},newmemos:[],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [id]);



const changeEditMode=()=>{

	setRequestErrors({errors:null});
	if(EventData.edit){
		setEventData({...EventData,data:{...EventData.orginal},edit:!EventData.edit,newmemos:[]})
	}else{
		setEventData({...EventData,edit:!EventData.edit})
	}
};




const tryToSave=()=>{



	let url="events/"+id+"/memos"
	let data={memos:[...EventData.data.memos,...EventData.newmemos]};
	console.log(data);

	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	console.log("SAIN",status)
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			//setUserData({...UserData,"edit":false});
			let data=v.data;
			console.log(v);
			setRequestErrors({errors:null});
			setEventData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),newmemos:[],loaded:true,edit:false});
			//apiCall.GET("users");
			
		}
	
	}).then(() => {
		//apiCall.GET('destinations');
		
	});
	




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};
const genres=(genres)=>{

return genres.map((genre,index) =>{
	return (<span>{genre.texts.fi.name}</span>)
});

}

const showtimes=(showtimes)=>{

	return showtimes.map((showtime,index) =>{
		return (<span>{showtime.date} {showtime.time} {showtime.place.texts.fi.name}</span>)
	});
	
	}


console.log(EventData);

	if(!EventData.loaded){

		return (<div></div>);
	}
	console.log("ED",EventData);
const getshowtype=(show)=>{

	let selected=show.events.find(g => g.id ==id);
	let cat_id=selected.pivot.category_id;
	let cat=(state.common.categories.find(c=>c.id==cat_id));
	if(cat){
		return cat.texts.fi.name;
	}

};
	const mapShowTimes=(showtimes)=>{


		return showtimes.map((showtime,index) =>{
			return <tr>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.show.texts.en.name}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{genres(showtime.show.genres)}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.date}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.time}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.place.texts.en.name}</Link></td>
				</tr>
		});

	};
	const changeOldText=(name,value,index)=>{



		let memos=[...EventData.data.memos];

		memos[index][name]=value;

		let data={...EventData.data};
		setEventData({...EventData,data:{...data,memos:memos}});


	};
	const rows=EventData.data.memos.map((memo,index) =>{

		//return mapShowTimes(date.showtimes);

		return <tr key={index}>


			<td className="memo-text">
				{EventData.edit?
				<InputText type="textarea"  onChange={changeOldText} index={index} data={memo} name="content" edit={true} />
				:memo.content}
				
				
				</td>
				{EventData.edit?<td><span className="remove" onClick={(e) => {
      				  removeOld(index);
   				 }}>Remove</span></td>:null}
			
			</tr>;
	  
	});
	
	const changeNewText=(name,value,index)=>{


		let memos=[...EventData.newmemos];

		memos[index][name]=value;
		setEventData({...EventData,newmemos:memos});




	};
	const removeOld=(index)=>{
	


		
					var data={...EventData.data};
					let memos=[...EventData.data.memos];
				   
					memos=memos.filter((value, curindex) => index !== curindex); 
			
					setEventData({...EventData,data:{...data,memos:memos}});
			
					
			
		

	};
	const removeNew=(index)=>{


				
					let memos=[...EventData.newmemos];
				  
					memos=memos.filter((value, curindex) => index !== curindex); 
			
					setEventData({...EventData,newmemos:[...memos]});




	};
	const newrows=EventData.newmemos.map((show,index) =>{

		//return mapShowTimes(date.showtimes);

		return <tr key={index}>

			
			<td className="memo-text"><InputText type="textarea"  onChange={changeNewText} index={index} data={memo} name="content" edit={true} /></td>
			
			{EventData.edit?<td><span className="remove" onClick={(e) => {
      				  removeNew(index);
   				 }}>Remove</span></td>:null}
			</tr>;
	  
	});


	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				<h2>{EventData.data.name}</h2>

				{<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>}

{EventData.edit?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}

{EventData.edit?<div className={"edit-btn "} onClick={(e) => {
      				  addNew(e);
   				 }}><span>Add new</span></div>:null}


				<EventTopNav id={EventData.data.id}/>

				

				
					

					<div className="form-blocks">
					<div className="form-block">

						
						
						<table className="table-new fringetable">
					

							<tbody>{newrows}{rows}</tbody>
						</table>

					</div>
					<div className="form-block">

						
					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
