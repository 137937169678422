
import React,{useRef} from "react";


export default function InputFile(props) {


    const inputFile = useRef(null) 

    const changeInputValue = (e) => {
		let name = e.target.name;
		
      
        props.onChange(name,e,props.index);
     
	};
   
    const errorkey=(props.useSimpleKey?props.name:"data."+props.name);


    const hasError=(props.errors && props.errors[errorkey]?true:false);


   


    const onButtonClick = () => {
        // `current` points to the mounted file input element
       inputFile.current.click();
      };
    //console.log(props.errors,"data."+props.name,hasError);
    const input=<div className="uploader">
        <span onClick={onButtonClick}>{props.button?props.button:"Select file"}</span>
        <input  ref={inputFile} type="file"  accept={props.accept}  name={props.name} 
    onChange={(e) => {
        changeInputValue(e);
    }} 
    
    /></div>;
    /*const d={props.edit==true?:{props.data[props.name]}};
   
    */

    /*<input type="file" onChange={(e) => {
			changeImage(e,index);
		}} accept="image/png, image/gif, image/jpeg" />*/
   
   const content=(props.edit===true?input:null);
	return (<div className={"input"+(hasError?" error":"")}><label>{props.label}</label>{content}{hasError}
    {(hasError?<p className="error-text">{props.errors[errorkey]}</p>:null)}
    </div>);
}
