export const config = {
	isDev: process.env.NODE_ENV !== "production",
	// isDev: false,
	apiUrl:
		process.env.NODE_ENV === "production"
			? "https://api.showtact.com/public/api"
			: "https://api.showtact.com/public/api"
		/*	: "http://localhost:8000/api"*/

	
};
