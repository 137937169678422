import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink,useLocation
	// etc.
  } from "react-router-dom";

export default function EventTopNav(props) {
	const { state, dispatch } = useStore();

  
	const location = useLocation();

	
	

	


	/**/ 
	const getClass=(item)=>{

		if(item.url=="/events/"+props.id+"/shows" && location.pathname.startsWith(item.url) && props.show_id){
			return "active-li";
		}
		if(location.pathname===item.url){

			return "active-li";

		}
		return null;

	};
	const renderErrors=()=>{

		console.log("orororo",props.errors,Object.keys(props.errors).length)

		let errors=Object.keys(props.errors).map(function(key) {
			
			return props.errors[key].map(function(v,index) {
				return  <div className="error">{v}</div>
			});

		});

		return <div className="errors">{errors}</div>



	}
	const links=[
        {url:"/events/"+props.id,name:"Info"},
		{url:"/events/"+props.id+"/schedule",name:"Schedule"},
		{url:"/events/"+props.id+"/shows",name:"Shows"},
		{url:"/events/"+props.id+"/memos",name:"Memo"},
		/*{url:"/shows/"+props.id+"/performers",name:"Performers"},
       // {url:"/shows/"+props.id+"/otherpersons",name:"Other persons"},
        {url:"/shows/"+props.id+"/links",name:"Links"},
		{url:"/shows/"+props.id+"/performances",name:"performances"},*/
      
      
		//{url:"/persons/"+props.id+"/bonukset",name:"Bonukset"},
        ];

		const nav = links.map((item,index) =>
  		<li key={index} className={getClass(item)}><NavLink  activeClassName="active"  to={item.url} exact>{item.name}</NavLink></li>
		);

	
	
	return (
		<div className="leafnav-outer">
		<div className="leafnav">
              
            {(nav?<ul>{nav}</ul>:null)}
              
               {/*<ul>
					
                    <li><NavLink activeClassName="active" to="/persons">Työntekijät</NavLink></li>
                    <li><NavLink activeClassName="active" to="/palkanlaskenta">Palkanlaskenta</NavLink></li>
                    <li><NavLink activeClassName="active" to="/maksaminen">Maksaminen</NavLink></li>
                    <li><NavLink activeClassName="active" to="/asetukset">Asetukset</NavLink></li>
               </ul>*/}
				
			
					
					
				</div>
				{props.errors?renderErrors():null}
			
		</div>
	);
}
