const STORAGE_KEY = "period_set";

const defaultState = {
	
	period:null
	
};
//alert(window.localStorage.getItem(STORAGE_KEY));
export const uiInitialState = (() => {
	
	defaultState.period=(window.localStorage.getItem(STORAGE_KEY)?window.localStorage.getItem(STORAGE_KEY):null);
	return defaultState;


})();

export const uiReducer = (state = uiInitialState, action) => {
	let ui = state;
	switch (action.type) {
		case "CHANGE_PERIOD":
	
			window.localStorage.setItem(STORAGE_KEY, action.period);
		
			ui={...ui,period:action.period};
			
		break;
		default:
			/*return ui;*/
		break;

	}
    return ui;
};