import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { userInitialState, userReducer } from "./user";
import { companiesInitialState, companiesReducer } from "./companies";

import { commonInitialState, commonReducer } from "./common";


import { uiInitialState, uiReducer } from "./ui";


import { usersInitialState, usersReducer } from "./users";


const initialState = {
	
	user: userInitialState,
   
	common:commonInitialState,
	
	ui:uiInitialState
};

const StoreContext = createContext(initialState);


const reducer = (state, action) => {
	const {
        user,
        companies,
		common,
		users,
		ui
	} = state;

	console.log("reducer action: ", action.type);

	const currentState = {
        user: userReducer(user, action),
      
		common:commonReducer(common,action),
		
		ui:uiReducer(ui,action)
	};

	return currentState;
};

export const StoreProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<StoreContext.Provider value={{ state, dispatch }}>
			{children}
		</StoreContext.Provider>
	);
};

StoreProvider.propTypes = {
	children: PropTypes.node,
};

export const useStore = (store) => {
	const { state, dispatch } = useContext(StoreContext);
	
	return { state, dispatch };
};
