
import InputSelect from "./InputSelect.js";


export default function InputClock(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;
      
       // props.onChange(name,value,props.index);
     
	};
    const getHours=()=>{
        var h=[];

        for(var i=0;i<24;i++){
            let v=i;
            if((""+v).length<2){
                v="0"+v;
            }
            h.push({id:i,name:v});
        }
        return h;
    };
    const getMinutes=()=>{
        var h=[];

        for(var i=0;i<60;i++){
            let v=i;
            if((""+v).length<2){
                v="0"+v;
            }
            h.push({id:i,name:v});
        }
        return h;
    };
    const changeHours=(n,v)=>{
        let value=(props.data[props.name]?props.data[props.name]:null);
        let time=null;
        if((v+"").length<2){
            v="0"+v;
        }
      
        if(value){
            let va=value.split(":");
            
            va[0]=v;

             time=va.join(":");



        }else{
            let d=[v,"00","00"]
             time=d.join(":");
        }
        props.onChange(props.name,time,props.index);
    };
    const changeMinutes=(n,v)=>{
        let value=(props.data[props.name]?props.data[props.name]:null);
        let time=null;
        let va=value.split(":");
        if((v+"").length<2){
            v="0"+v;
        }
        va[1]=v;
        time=va.join(":");
        props.onChange(props.name,time,props.index);


    };
    let hours=null;
    let minutes=null;
    let value=(props.data[props.name]?props.data[props.name]:null);
    if(value){
        let va=value.split(":");
        hours=parseInt(va[0]);
        minutes=parseInt(va[1]);
    }else{


    }
   console.log("MINUTES",minutes);
	return (<div className="input input-clock">
        <InputSelect edit={true} onChange={changeHours} name="hours"  data={{hours:hours}} options={getHours()}/>
       <span>:</span>
        <InputSelect edit={true} onChange={changeMinutes} name="minutes"  data={{minutes:minutes}} options={getMinutes()}/>
        </div>);
}
