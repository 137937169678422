import React,{useState,useEffect,createRef} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";


import InputBoolean  from "./form/InputBoolean.js";
import { apiClient } from "../apiCall";
import {formatSeason,getEnnakonPidatys,getTaxesForPeriod,getGlobalSettingsForPeriod, getUserSettingsForPeriod, getSettingValue,getBonus} from "../commonFunctions";
import AdditionSubstraction from "./AdditionSubstraction.js";
import Kulukorvaukset from "./Kulukorvaukset.js";
import PreviewTable from "./PreviewTable.js";
import TeamBonus from "./Teambonus.js";
import AlaisetBonus from "./form/AlaisetBonus.js";
import OmatAlaisetBonus from "./form/OmatAlaisetBonus.js";
import {getPalkkaObject,ulosOtonMaara,getTyel} from "../payWageFunctions";
import WageDisplay from "./WageDisplay.js"
import TopNav from "./TopNav.js";
import PreviewPalkka from "./PreviewPalkka.js";
import PreviewKaava from "./PreviewKaava.js";
import PreviewYritysLainat from "./PreviewYritysLainat.js";
import PreviewNettoVahennykset from "./PreviewNettoVahennykset.js";
import PreviousWageNav from "./PreviousWageNav.js";
import Yrityslainat from "./Yrityslainat.js";
import Period from "./form/Period.js";
import AllBonuses from "./AllBonuses.js";
import WageSheets from "./WageSheets.js";
import {
  
    useParams
  } from "react-router-dom";
import { findAllInRenderedTree } from "react-dom/test-utils";

 const defaultAdditionSubstraction={

	description:"",
	value:0.0,
	multiply:1

 };
export const defaultWageData = {
		period:null,
		ilmoitettu_myynti:0,
		laskutettu_myynti:0,
		provisio:0,
		koulutuspaivien_lkm:0,
		sairasloma_lkm:0,
		sairasloma_price:10,

		lomapalkka_lkm:0,
		lomapalkka_price:0,
		lomakorvaus_lkm:0,
		lomakorvaus_price:0,
		
		koulutuskilometrit:0,
		ennakko_provisio:0.0,
		additionsandsubstractions:[],
		kilometrit_lkm:0,
		paivarahat_lkm:0,
		paivarahatpuoli_lkm:0,
		lounaritmaara:null,
		maksuvapaa:0,
		calculation:null,
		confirm:false,
		storing:false,
		confirmed:false,
		SairaslomaOpen:false,
		LomaPalkkaOpen:false,
		KoulutusPaivatOpen:false
		
	
};

export const defaultResultData = {
	calculation:null,
	loading:false,
	calculate_new:null
};
export const defaultPrevious = {
	data:null,
	loading:true,
};
export const defaultPreviousData = {
	id:null
};

export const defaultViewMode = {
	mode:"laskenta"
};
export default function PayWage() {
	const { state, dispatch } = useStore();
	const {common}=state;

	let { id,period } = useParams();
	const [ViewMode,setViewMode]= useState({
		...defaultViewMode
		
    });
	
	
	const [PreviousData,setPreviousData]= useState({
		...defaultPreviousData
		
    });


	const [Previous, setPrevious] = useState({
		...defaultPrevious
		
    });


    const [WageData, setWageData] = useState({
		...defaultWageData
		
    });
	const [ResultData, setResultData] = useState({
		...defaultResultData
		
    });
	
	

	const apiCall = apiClient(state, dispatch);		
	



	  const apiResponse=(data,setOpens)=>{

		

	
		
	
		if(data){
			

			let SairaslomaOpenDefault=false;
			if(data.sairasloma_lkm!=0 || data.sairasloma_price!=0){
				SairaslomaOpenDefault=true;
			}

			let LomaPalkkaOpenDefault=false;
			if(data.lomapalkka_lkm!=0 || data.lomapalkka_price!=0){
				LomaPalkkaOpenDefault=true;
			}
			let KoulutusPaivatOpenDefault=false;
			if(data.koulutuspaivien_lkm!=0 || data.koulutuskilometrit!=0){
				 KoulutusPaivatOpenDefault=true;
			
			}
			
			setWageData({...data,SairaslomaOpen:SairaslomaOpenDefault,LomaPalkkaOpen:LomaPalkkaOpenDefault,KoulutusPaivatOpen:KoulutusPaivatOpenDefault});
			updatePreview({...data});
		}


	  };


	  useEffect(() => {
		
		//if(common.current_period==null){ return; }
		
		


		fetch( config.apiUrl+"/persons/"+id+"/wagesconfirmed/"+period,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setPrevious({data:(data),loading:false}))

	  }, [id]);
	

	






	useEffect(() => {
		
		//if(common.current_period==null){ return; }
		
		


		fetch( config.apiUrl+"/persons/"+id+"/wage/"+period,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data =>apiResponse(data,true))

	  }, []);
	
	
	  
    const updateWageData=(name,value)=>{
        setWageData({...WageData,[name]:value})
    };
	
	const loadPrevious=(previous_id)=>{
		alert("PREVV"+previous_id);

		//<WageDisplay  id={WageData.id}/>
		setPreviousData({id:previous_id});


			/*fetch( config.apiUrl+"/persons/"+id+"/wage/"+period_wanted,{headers: new Headers({
				'Authorization': 'Bearer '+state.user.token
				
			  })})
			  .then((res) => res.json())
			  .then(data =>previousApiResponse(data,true))*/
	
		





	};
	const saveDataAndConfirm=(data)=>{

		//setWageData({...WageData,"storing":true});
		

		let url="wages/"+data.id+"/save";
		///let data={...WageData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
					console.log("CALC DATA",response.data);



					setResultData({...ResultData,calculation:{...response.data}});
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		
		

	
	};


	const updatePreview=(data)=>{

		//setWageData({...WageData,"storing":true});
		

		let url="wages/"+data.id+"/preview";
		///let data={...WageData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
					console.log("CALC DATA",response.data);



					setResultData({...ResultData,calculation:{...response.data.calculate},calculate_new:{...response.data.calculate_new}});
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		
		

	
	};



	
	const tryToStore=(e)=>{

		setWageData({...WageData,"storing":true});
		let url="wages/"+WageData.id+"/confirm";
		///let data={...WageData};
		
		apiCall.ADD(url, {...WageData},function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){



					console.log("CALC DATA",response.data);
					apiResponse(response.data);


					
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		return;

	/*	let url="confirm/"+WageData.id;
		let data=getPalkkaObject(UserData,common,WageData);
		
		apiCall.ADD(url, data,function(status,err_msgs=null){
				
				alert(status);
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
		
			
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
	

*/
	};

	const makeConfirmation=(e)=>{

			tryToSave(null,function(){
					
				setWageData({...WageData,"confirm":true})

			});
			//saveDataAndConfirm({...WageData});
			//setWageData({...WageData,"confirm":true})

	};
	const  RemoveAdditionSubstraction=(index)=>{

		
		let a=[...WageData.additionsandsubstractions];
		a.splice(index, 1);
		setWageData({...WageData,additionsandsubstractions:a})

	};
	const AddNewAdditionSubstraction=(e)=>{


		let a=[...WageData.additionsandsubstractions];
		
		a.push({...defaultAdditionSubstraction});
		
		setWageData({...WageData,additionsandsubstractions:a})

	};
	const  ChangeAdditionSubstraction=(name,value,index)=>{

		let a=[...WageData.additionsandsubstractions];

		a[index][name]=value;
		setWageData({...WageData,additionsandsubstractions:a})

	};
	const setYritysLainaLyhennys=(value)=>{

		/*let lainat=[...WageData.yrityslainat];
		console.log(lainat);
		lainat[index].era=value;*/
		console.log("-----");
		console.log(WageData.yrityslainan_lyhennys,value);
		setWageData({...WageData,yrityslainan_lyhennys:value})		

	};
	const changeInputValueAndUpdate =(name,value)=>{

		setWageData({...WageData,[name]:value});

		updatePreview({...WageData,[name]:value});

	};
	const changeInputValue = (name,value) => {
	
       
        setWageData({...WageData,[name]:value})
        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	const onBlur = (name,value) => {
	
		/*fetch( config.apiUrl+"/persons/"+id+"/wage/preview/"+WageData.period)
		.then((res) => res.json())
		.then(data =>apiResponse(data));

		*/
		updatePreview({...WageData});
        //dispatch({ type: "LOGINFORM_INPUT", key: name, value: value }, [dispatch]);
	};
	
	

	if(!WageData.period){
			return (<div>Ladataan</div>);
	}

	const SairaslomaOpen=(WageData.SairaslomaOpen===true);
	
	const LomaPalkkaOpen=(WageData.LomaPalkkaOpen===true);
	const KoulutusPaivatOpen=(WageData.KoulutusPaivatOpen===true);
	
const toggleSairaslomaOpen=()=>{

	
	setWageData({...WageData,SairaslomaOpen:!SairaslomaOpen})
};

const toggleLomaPalkkaOpen=()=>{

	
	setWageData({...WageData,LomaPalkkaOpen:!LomaPalkkaOpen})
};
const toggleKoulutusPaivatOpen=()=>{

	
	setWageData({...WageData,KoulutusPaivatOpen:!KoulutusPaivatOpen})
};

	const CURRENT_PERIOD=WageData.period;

	








/*
const netto_vahennykset=WageData.ownbuys.map(buy=>{

return <tr key={buy.id}><td>{buy.value} €</td><td>{buy.description}</td></tr>

});*/

//const netto_vahennykset_total=(WageData.ownbuys.length!==0?WageData.ownbuys.reduce((a,b)=>a.value+b.value):0);


	const changePeriod=(d)=>{


		
		
	};	

const tryToSave=(e,callback=null)=>{


		console.log("SAEND",WageData)

	let url="wages/"+WageData.id;
	let data={...WageData};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,response){
			console.log("TLI");
			
			if(status==400){
	
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){

				if(callback){

					callback();
				}else{
					console.log("UPD....."  ,response.data);
					 setWageData({...response.data})
				}
		
			}
	
		}).then(() => {
			//apiCall.GET('destinations');
			
		});
	


};

const older=(PreviousData.id!==null?<WageDisplay  id={PreviousData.id}/>:null);

	if(WageData.confirmed){

		return (<div><TopNav  /><WageDisplay print={true} id={WageData.id}/></div>)
	}
	if(WageData.confirm){
		
		return (<div><TopNav  /><WageDisplay store={tryToStore} topic={"Vahvista"} id={WageData.id}/></div>);
	}
	const isKKPalkka=(WageData.calculation.role.roles_id==4?true:false);
	console.log("WWW",WageData);
	return (
		<div className="mycontent wage-content medic">


				

				<div className="inner">
				<div className="box">
				<h2>{WageData.name} {formatSeason(WageData.period)} <div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div> { ResultData && ResultData.calculation && ResultData.calculation.canbeconfirmed?<div className="save-btn confirm" onClick={makeConfirmation}><span>Tallenna ja Vahvista</span></div>:null}</h2>
				<PreviousWageNav period={WageData.period} loadPrevious={loadPrevious} items={Previous}/>
				
				<div class="both">
				<div class="left">
				<div className="form-blocks">
					
					<div className="form-block">
					{(isKKPalkka===false?
					<div className="row cols-4">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue} label="Ilmoitettu myynti" name="ilmoitettu_myynti" data={WageData} edit={true}/>
					</div>

					<div className="col">
						<InputDouble onBlur={onBlur} onChange={changeInputValue} name="laskutettu_myynti" label="Laskutettu myynti" data={WageData} edit={true}/>
					</div>
					<div className="col">
							<InputDouble onBlur={onBlur}v onChange={changeInputValue}  label="Provisio" name="provisio" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Ennakko provisio" name="ennakko_provisio" data={WageData} edit={true}/>
					</div>
					
					
				</div>	
:null)}
				<h4>Kulukorvaukset</h4>
				<div className="row col-4">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Kilometrien lkm" name="kilometrit_lkm" data={WageData} edit={true}/>
					</div>
						<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Päivärahat lkm" name="paivarahat_lkm" data={WageData} edit={true}/>
					</div>
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Puolipäivärahat lkm" name="paivarahatpuoli_lkm" data={WageData} edit={true}/>
					</div>
				
					<div className="col">
						{WageData.lounaritActive?
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lounasetelien määrä" name="lounaritmaara" data={WageData} edit={true}/>
							:null}
					</div>
					
				</div>
				
				


					{((WageData.koulutuspaivatActive || WageData.koulutusKmActive)?<div>	
				<h4 className={(KoulutusPaivatOpen?"arrow open":"arrow closed")} onClick={toggleKoulutusPaivatOpen}>Koulutuspäivät</h4>

{true?
				
				<div className="row"  className={"row"+(KoulutusPaivatOpen?" open":" closed")}>
				
				{WageData.koulutuspaivatActive?
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutuspäivien lkm" name="koulutuspaivien_lkm" data={WageData} edit={true}/>
				</div>
				:null}
				{WageData.koulutusKmActive?
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutus kilometrit" name="koulutuskilometrit" data={WageData} edit={true}/>
				
				</div>
				:null}
				
					
				

			</div>:null}

			</div>:null)}

			

			

			<h4 className={(SairaslomaOpen?"arrow open":"arrow closed")}  onClick={toggleSairaslomaOpen}>Sairaslomapalkka</h4>
				<div className={(SairaslomaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma päiviä" name="sairasloma_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma €/pvm" name="sairasloma_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>

				<h4 className={(LomaPalkkaOpen?"arrow open":"arrow closed")}  onClick={toggleLomaPalkkaOpen}>Lomapalkka</h4>
				<div className={(LomaPalkkaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka" name="lomapalkka_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka €/pvm" name="lomapalkka_price" data={WageData} edit={true}/>
					</div>
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus" name="lomakorvaus_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus €/pvm" name="lomakorvaus_price" data={WageData} edit={true}/>
					</div>
					
				</div>
				<div className="yrityslainat-editor">
				<h4>Yrityslainat (lyhennettävät ajanjaksolla)</h4>
					<div className="row">
						<div className="col smalltable">
							<Yrityslainat onBlur={onBlur} setYritysLainaLyhennys={setYritysLainaLyhennys} data={WageData.yrityslainat} value={WageData.yrityslainan_lyhennys} default={WageData.oletus_lyhennys}/>
						</div>
					</div>	
				</div>
				
				{WageData.hasUlosotto?
				<div><h4>Ulosotto (maksuvapaa)</h4>
				<div className="row">
					<div className="col ">
				<InputBoolean  onChange={changeInputValueAndUpdate} data={WageData} name="maksuvapaa" edit={true}/>
					</div>
				</div></div>:null}

				<div className="provision-editor">
					<h4>Provision lisäys/vähennys</h4>
					<div className="smalltable">
					<AdditionSubstraction onBlur={onBlur} palkkarows={[]} onChange={ChangeAdditionSubstraction} data={WageData}  remove={RemoveAdditionSubstraction} add={AddNewAdditionSubstraction}/>
					
			

					</div>

					</div>






				


						</div>

						<div>{ResultData.calculation!==null && ResultData.calculation.bonuses_temp?<AllBonuses data={ResultData.calculation.bonuses_temp}/>:null}
</div>

<div className="smalltable">{ResultData.calculation!==null?<PreviewNettoVahennykset topic="Netto vähennykset" data={ResultData.calculation.netto_vahennykset}/>
				:null}</div>
						</div>

					</div>
					<div className="right right-laskenta">

				

				
	<ul className="palkka-nav">

	<li className={(ViewMode.mode==="laskenta"?"active":null)}><span  onClick={(e) => {
        setViewMode({mode:"laskenta"});
    	}




		} >Laskenta</span></li>
		<li className={(ViewMode.mode==="yhteenveto"?"active":null)}><span  onClick={(e) => {
        setViewMode({mode:"yhteenveto"});
    	}




		} >Yhteenveto</span></li>
		<li  className={(ViewMode.mode==="palkkalaskelma"?"active":null)}><span  onClick={
			(e) => {
				setViewMode({mode:"palkkalaskelma"});
				}
			
			} >Palkkalaskelma</span></li>
		
		</ul>
	

<div className="results-preview">
					
					{ResultData.calculate_new!==null && ViewMode.mode==="yhteenveto"?<WageDisplay topic={"Yhteenveto"} id={null}  simple={true} data={ResultData.calculate_new}/>:null}

					{ResultData.calculate_new!==null && ViewMode.mode=="palkkalaskelma"?<WageDisplay topic={"Palkkalaskelma"} id={null}  simple={false} data={ResultData.calculate_new}/>
				:null}


					{ResultData.calculate_new!==null && ViewMode.mode=="laskenta"?<WageSheets  calculation={ResultData.calculate_new}/>
				:null}

				{/*
					<hr/><p>tässä vanha</p>
					{ResultData.calculation!==null?<PreviewKaava topic="Tulos" extraclass="tulos" data={ResultData.calculation}/>
				:null}	
				{ResultData.calculation!==null && ResultData.calculation.bonuses_temp?<AllBonuses data={ResultData.calculation.bonuses_temp}/>:null}

				{ResultData.calculation!==null?<PreviewPalkka  topic="Laskenta" data={ResultData.calculation}/>:null}
					

				{ResultData.calculation!==null?<PreviewTable key_name='luontoisetu' topic="Luontoisedut" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewTable key_name='kulukorvaus' topic="Kulukorvaukset" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewNettoVahennykset topic="Netto vähennykset" data={ResultData.calculation.netto_vahennykset}/>
				:null}

					*/}




</div>


					</div>
					
					</div>
					</div></div>
			
<div className="inners forms" class="hidden">
			<div className="inner leftinner">
				
				<div className="box">
				
					
                       


				<form
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						//tryLogin();
					}}
				>
				<div className="laskenta">
				

				
			
				
				<div className="row">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue} label="Ilmoitettu myynti" name="ilmoitettu_myynti" data={WageData} edit={true}/>
					</div>

					<div className="col">
						<InputDouble onBlur={onBlur} onChange={changeInputValue} name="laskutettu_myynti" label="Laskutettu myynti" data={WageData} edit={true}/>
					</div>
					
					
					
				</div>	
				<div className="row">
					
					<div className="col">
							<InputDouble onBlur={onBlur}v onChange={changeInputValue}  label="Provisio" name="provisio" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Ennakko provisio" name="ennakko_provisio" data={WageData} edit={true}/>
					</div>
					
					
				</div>
				<h4>Kulukorvaukset</h4>
				<div className="row three">
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Kilometrien lkm" name="kilometrit_lkm" data={WageData} edit={true}/>
					</div>
						<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Päivärahat lkm" name="paivarahat_lkm" data={WageData} edit={true}/>
					</div>
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Puolipäivärahat lkm" name="paivarahatpuoli_lkm" data={WageData} edit={true}/>
					</div>
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lounasetelien määrä" name="lounaritmaara" data={WageData} edit={true}/>

					</div>
					
				</div>





				







			

				




					{true?
				<div className="row">
					
					
					
					<div className="col">
					<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lounasetelien määrä" name="lounaritmaara" data={WageData} edit={true}/>

					</div>
					<div className="col">
						
					</div>

				</div>
				:null}

<h4 className={(KoulutusPaivatOpen?"arrow open":"arrow closed")} onClick={toggleKoulutusPaivatOpen}>Koulutuspäivät</h4>

{true?
				
				<div className="row"  className={(KoulutusPaivatOpen?"row open":"row closed")}>
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutuspäivien lkm" name="koulutuspaivien_lkm" data={WageData} edit={true}/>
				</div>
				<div className="col">
				<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Koulutus kilometrit" name="koulutuskilometrit" data={WageData} edit={true}/>
				
				</div>
				
					
				

			</div>:null}
				
				<h4 className={(SairaslomaOpen?"arrow open":"arrow closed")}  onClick={toggleSairaslomaOpen}>Sairaslomapalkka</h4>
				<div className={(SairaslomaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma päiviä" name="sairasloma_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Sairasloma €/pvm" name="sairasloma_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>

				<h4 className={(LomaPalkkaOpen?"arrow open":"arrow closed")}  onClick={toggleLomaPalkkaOpen}>Lomapalkka</h4>
				<div className={(LomaPalkkaOpen?"row open":"row closed")}>
					
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka" name="lomapalkka_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomapalkka €/pvm" name="lomapalkka_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>

				
				<div className="row">
				<h4>Lomakorvaus</h4> 
					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus" name="lomakorvaus_lkm" data={WageData} edit={true}/>
					</div>

					<div className="col">
							<InputDouble onBlur={onBlur} onChange={changeInputValue}  label="Lomakorvaus €/pvm" name="lomakorvaus_price" data={WageData} edit={true}/>
					</div>
					
					
				</div>




				<h4>Yrityslainat</h4>
				<div className="row">
					<div className="col smalltable">
						<Yrityslainat onBlur={onBlur} setYritysLainaLyhennys={setYritysLainaLyhennys} data={WageData.yrityslainat} value={WageData.yrityslainan_lyhennys} default={WageData.oletus_lyhennys}/>
					</div>
				</div>	
					





			
					<h4>Ulosotto</h4>
					<div className="col">

					<InputBoolean  onChange={changeInputValueAndUpdate} data={WageData} name="maksuvapaa" edit={true}/>
					</div>
				<div className="smalltable">
					<h4>Provision lisäys/vähennys</h4>
					<AdditionSubstraction palkkarows={[]} onChange={ChangeAdditionSubstraction} data={WageData}  remove={RemoveAdditionSubstraction} add={AddNewAdditionSubstraction}/>
					
				</div>
					
				<div>xx
				</div>
				

				
			
				</div>
					

				
				

				
				
					
				</form>
				</div>
			</div>


			<div className="inner rightinner">
				
				<div className="box">

				<div>

	

				{ResultData.calculation!==null?<PreviewKaava topic="Tulos" data={ResultData.calculation}/>
				:null}	
				{ResultData.calculation!==null && ResultData.calculation.bonuses_temp?<AllBonuses data={ResultData.calculation.bonuses_temp}/>:null}

				{ResultData.calculation!==null?<PreviewPalkka  topic="Laskenta" data={ResultData.calculation}/>:null}
					

				{ResultData.calculation!==null?<PreviewTable key_name='luontoisetu' topic="Luontoisedut" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewTable key_name='kulukorvaus' topic="Kulukorvaukset" data={ResultData.calculation}/>:null}
				{ResultData.calculation!==null?<PreviewNettoVahennykset topic="Netto vähennykset" data={ResultData.calculation.netto_vahennykset}/>
				:null}

			
				</div>	
				</div></div>
		</div></div>
	);
}
