import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputBoolean from "./form/InputBoolean.js";

import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";
import EventTopNav from "./EventTopNav.js";


import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultEventData={
	
	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};


export const DefaultRequestErrors={

	errors:null
}
export default function EventShows() {
	const { state, dispatch } = useStore();
    const { id } = useParams();
  
    const [EventData, setEventData] = useState({
		...defaultEventData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	const changeEditMode=()=>{

	
		if(EventData.edit){
			setEventData({...EventData,data:{...EventData.orginal},edit:!EventData.edit})
		}else{
			setEventData({...EventData,edit:!EventData.edit})
		}
	};
	

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",id);
		fetch( config.apiUrl+"/events/"+id+"/info",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [id]);









	  const tryToSave=()=>{



		let url="events/"+id+"/published"

		let ds=[];
		console.log("sends",EventData.data);
		for(var i=0;i<EventData.data.shows.length;i++){

			ds.push({show_active:EventData.data.shows[i].pivot.show_active,show_id:EventData.data.shows[i].id});
		}
		let data={data:ds};
	
	
		
		apiCall.ADD(url, data,function(status,err_msgs=null,v){
		console.log("SAIN",status);

			if(status==400){
				console.log(err_msgs);
				console.log(RequestErrors);
				//setErrors({errors:{...err_msgs}})
				setRequestErrors({errors:{...err_msgs}});
				//setFolderData({...FolderData,errors:err_msgs})
			}else if(status==200){
				//setUserData({...UserData,"edit":false});
				let data=v.data;
				console.log(v);
				setRequestErrors({errors:null});
				setEventData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true,edit:false});
				//apiCall.GET("users");
				
			}
		
		}).then(() => {
			//apiCall.GET('destinations');
			
		});
		
	
	
	
	
		
	};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};
const genres=(genres)=>{

return genres.map((genre,index) =>{
	return (<span>{genre.texts.fi.name}</span>)
});

}

const showtimes=(showtimes)=>{

	return showtimes.map((showtime,index) =>{
		return (<span>{showtime.date} {showtime.time} {showtime.place.texts.fi.name}</span>)
	});
	
	}


console.log(EventData);

	if(!EventData.loaded){

		return (<div></div>);
	}
	console.log("ED",EventData);
const getshowtype=(show)=>{

	let selected=show.events.find(g => g.id ==id);
	let cat_id=selected.pivot.category_id;
	let cat=(state.common.categories.find(c=>c.id==cat_id));
	if(cat){
		return cat.texts.fi.name;
	}

};
	const changePublish=(name,value,index)=>{



		let evsall=EventData.data.shows.filter(s=>(s.approved===1 || s.is_hakemus===0))
		let id=(evsall[index].id);


let r_index=EventData.data.shows.findIndex((o=>o.id==id));



		let shows=[...EventData.data.shows];



		shows[r_index].pivot[name]=value;
		setEventData({...EventData,data:{...EventData.data,shows:[...shows]}});


		///EventData.data.shows[index].pivot.show_active=value;


	};
	const mapShowTimes=(showtimes)=>{


		return showtimes.map((showtime,index) =>{
			return <tr>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.show.texts.en.name}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{genres(showtime.show.genres)}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.date}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.time}</Link></td>
				<td><Link to={"/events/"+id+"/show/"+showtime.show.id}>{showtime.place.texts.en.name}</Link></td>
				</tr>
		});

	};
	const getstatus=(show)=>{

		if(show.is_hakemus==1){
			if(show.approved==1){
				return "Application, approved";
			}else{

				return "Application, not approved";
			}
			
		}else{

			return "Approved";
		}
	};
	const getPreviews=(show)=>{

		return (<span className="pre_links">
			<a href={"https://finfringe.fi/ohjelmisto?show="+show.id+"&preview="+show.preview} target="_blank">FI</a> 
			<a href={"https://beta-sv.finfringe.fi/ohjelmisto?show="+show.id+"&preview="+show.preview} target="_blank">SV</a> 
			<a href={"https://beta-en.finfringe.fi/ohjelmisto?show="+show.id+"&preview="+show.preview} target="_blank">EN</a>
			
			</span>);

	};
	const rows=EventData.data.shows.filter(s=>(s.approved===1 || s.is_hakemus===0)).map((show,index) =>{

		//return mapShowTimes(date.showtimes);

		return <tr key={index}>
			<td><Link to={"/events/"+id+"/shows/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+id+"/shows/"+show.id}>{getshowtype(show)}</Link></td>
			<td>{getstatus(show)}</td>
			<td>{(id==16?getPreviews(show):"-")}</td>
			<td>{EventData.edit?<InputBoolean onChange={changePublish} name="show_active" data={show.pivot} edit={true} index={index} />:(show.pivot.show_active?"yes":"no")}</td>
			
			</tr>;
	  
	});


	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				<h2>{EventData.data.name}</h2>{<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>}


{EventData.edit?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}



				<EventTopNav id={EventData.data.id}/>

				{/*<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>*/}

				
					

					<div className="form-blocks">
					<div className="form-block">

						
						
						<table className="table-new fringetable">
						<thead><tr><th>Show</th><th>Category</th><th>Status</th><th>Preview</th><th>Published to WWW</th></tr></thead>

							<tbody>{rows}</tbody>
						</table>

					</div>
					<div className="form-block">

						
					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
