import logo from './logo.svg';

import { useStore } from "./store/useStore";
import React, { useEffect, useState } from 'react';
import { apiClient } from "./apiCall";
import { StoreProvider } from "./store/useStore";
import LoginForm from "./components/LoginForm";
import Persons from "./components/Persons";
import Person from "./components/Person";
import Place from "./components/Place";

import PersonTaxes from "./components/PersonTaxes";
import PersonRoles from "./components/PersonRoles";

import BonusesAll  from "./components/BonusesAll";
import SideNavigation from "./components/SideNavigation";
import {BrowserRouter, Switch,Route} from "react-router-dom";
import Palkanlaskenta from './components/Palkanlaskenta';
import CreditCard from './components/CreditCard';
import Asetukset from './components/Asetukset';
import BonusChartMyyjat from './components/BonusChartMyyjat';

import BonusChartOsasto from './components/BonusChartOsasto';
import BonusChartAlaiset from './components/BonusChartAlaiset';
import PersonSettings  from "./components/PersonSettings";
import PersonLoan  from "./components/PersonLoan";
import PersonAlaiset from "./components/PersonAlaiset";
import PersonBonuses  from "./components/PersonBonuses";
import PayWage from './components/PayWage';
import PAYWAGE_BACK from "./components/PAYWAGE_BACK";
import AddPerson from "./components/AddPerson.js";
import YritysLainatAll  from "./components/YritysLainatAll.js";
import BonusChartEditor from "./components/form/BonusChartEditor.js";

import Events from './components/Events';
import EventShows from './components/EventShows';

import AddEvent from "./components/AddEvent.js";
import Event from './components/Event';
import EventSchedule from './components/EventSchedule';

import Applications from "./components/Applications.js";

import Show from './components/Show';
import ShowGenres from './components/ShowGenres';
import ShowSponsors from './components/ShowSponsors';
import ShowImages from './components/ShowImages';

import Shows from './components/Shows';
import ShowPerformers from './components/ShowPerformers';
import ShowPerformances from './components/ShowPerformances';
import ShowLinks from './components/ShowLinks';
import ShowEvents from './components/ShowEvents';

import Places from './components/Places';
import Sponsors from './components/Sponsors';

import EventShow from "./components/EventShow.js";
import EventShowFiles from "./components/EventShowFiles.js";

import ShowOtherPersons from "./components/ShowOtherPersons.js";
import AddGenre from "./components/AddGenre.js";
import Categories from "./components/Categories.js";
import UsersEditor from "./components/UsersEditor.js";
import EventMemos from "./components/EventMemos.js";

import ApplicationInfo from "./components/ApplicationInfo.js";


function App() {

  const { state, dispatch } = useStore();
  const apiCall = apiClient(state, dispatch);

  const fetchUsers = () => {
    // dispatch({ type: "FETCH_LOAD" });
   
    // apiCall.GET("users");


   };
   const fetchCommon = () => {
    // dispatch({ type: "FETCH_LOAD" });
   
     apiCall.GET("common");


   };
   const fetchCompanies= () => {
    // dispatch({ type: "FETCH_LOAD" });
   
    // apiCall.GET("companies");


   };

   
  useEffect(() => {
   if(state.user.loggedIn){
     
       // fetchUsers();
       // fetchCompanies();
        fetchCommon();
       console.log("OKKO");
   
    } 
  
   
   }, [state.user]);
   
   
   
   console.log(state.user);
   
  
    if (state.user.loggedIn==false){
      return (<LoginForm/>);
     }
  return (
    <BrowserRouter>
    <main>
    <div className="App">
      <div className="left"><SideNavigation/></div>
      <div className="right">
      <Switch>
      <Route path="/palkanlaskenta" exact>

<Palkanlaskenta/>
</Route>


<Route path="/netto" exact>

        <CreditCard/>
        </Route>
        <Route path="/events" exact><Events/></Route>

        <Route path="/events/add" exact children={<AddEvent/>}/>

        <Route path="/events/:id" exact children={<Event/>}/>
        <Route path="/events/:id/schedule" exact children={<EventSchedule/>}/>

        <Route path="/events/:id/memos" exact children={<EventMemos/>}/>

        <Route path="/events/:event_id/shows/:show_id" exact children={<Show/>}/>


        <Route path="/events/:event_id/shows/:show_id/application" exact><ApplicationInfo/></Route>
        <Route path="/events/:event_id/shows/:show_id/performers" exact children={<ShowPerformers/>}/>
        <Route path="/events/:event_id/shows/:show_id/otherpersons" exact children={<ShowOtherPersons/>}/>

        <Route path="/events/:event_id/shows/:show_id/genres" exact children={<ShowGenres/>}/>
        <Route path="/events/:event_id/shows/:show_id/images" exact children={<ShowImages/>}/>
        <Route path="/events/:event_id/shows/:show_id/sponsors" exact children={<ShowSponsors/>}/>
        <Route path="/events/:event_id/shows/:show_id/links" exact children={<ShowLinks/>}/>
       
       




        <Route path="/events/:event_id/shows/:show_id/workgroup" exact children={<EventShow/>}/>



        <Route path="/events/:event_id/shows/:show_id/files" exact children={<EventShowFiles/>}/>
       

        
        <Route path="/events/:id/shows" exact children={<EventShows/>}/>
        

        <Route path="/places" exact><Places/></Route>
        <Route path="/sponsors" exact><Sponsors/></Route>
        <Route path="/places/:place_id" exact><Place/></Route>
        <Route path="/applications" exact><Applications/></Route>

        <Route path="/shows/:show_id/application" exact><ApplicationInfo/></Route>

        <Route path="/shows" exact><Shows/></Route>
        <Route path="/shows/:show_id" exact children={<Show/>}/>
        <Route path="/shows/:show_id/links" exact children={<ShowLinks/>}/>
        <Route path="/shows/:show_id/genres" exact children={<ShowGenres/>}/>
        <Route path="/shows/:show_id/sponsors" exact children={<ShowSponsors/>}/>
        <Route path="/shows/:show_id/images" exact children={<ShowImages/>}/>

        <Route path="/shows/:show_id/performers" exact children={<ShowPerformers/>}/>
        <Route path="/shows/:show_id/otherpersons" exact children={<ShowOtherPersons/>}/>



        <Route path="/shows/:show_id/performances" exact children={<ShowPerformances/>}/>




        <Route path="/shows/:show_id/events" exact children={<ShowEvents/>}/>





        <Route path="/genres/" exact children={<AddGenre/>}/>
        <Route path="/categories/" exact children={<Categories/>}/>


        <Route path="/persons/:id" exact children={<Person/>}/>
        <Route path="/persons/:id/taxes" exact children={<PersonTaxes/>}/>
        <Route path="/persons/:id/settings" exact children={<PersonSettings/>}/>
        <Route path="/persons/:id/loan" exact children={<PersonLoan/>}/>
        <Route path="/persons/:id/roles" exact children={<PersonRoles/>}/> 
        
        <Route path="/persons/:id/bonukset" exact children={<PersonBonuses/>}/>
        


        <Route path="/palkanlaskenta/:id/:period" exact children={<PayWage/>}/>

        <Route path="/asetukset" exact children={<Asetukset/>}/>
        {/*}<Route path="/asetukset/bonuschart" exact children={<BonusChartMyyjat/>}/>
        <Route path="/asetukset/bonuschartosasto" exact children={<BonusChartOsasto/>}/>
       
        <Route path="/asetukset/bonuschartalaiset" exact children={<BonusChartAlaiset/>}/>
  */}
        <Route path="/yrityslainat" exact children={<YritysLainatAll/>}/>
      
        <Route path="/bonuses" exact children={<BonusesAll/>}/>
        <Route path="/bonuses/:id" exact children={<BonusChartEditor/>}/>

        {state.user.role===1?<Route path="/users" exact children={<UsersEditor/>}/>:null}

      </Switch>
     
    

      </div>
    </div>
    </main>
    </BrowserRouter>
  );
}

export default App;
