import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import InputText from "./form/InputText";

import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import ShowTopNav from "./ShowTopNav.js";
import EventTopNav from "./EventTopNav.js";
import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultShowData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function ShowOtherPersons() {
	const { state, dispatch } = useStore();
    const { show_id,event_id } = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	const remove=(index)=>{

		var data={...ShowData.data};
        var performers=[...ShowData.data.otherpersons];
       
        performers=performers.filter((value, curindex) => index !== curindex); 

       
        setShowData({...ShowData,data:{...data,otherpersons:[...performers]}});
	};
    const addPerformer=(target)=>{
        var data={...ShowData.data};
       // var otherpersons=[...ShowData.data.otherpersons];
       
        data.otherpersons=[{lastname:"",firstname:"",order_persons:-1,texts:{}},
         ...data.otherpersons];
       
		 data.otherpersons.sort((a,b) => a.order_persons - b.order_persons);


        setShowData({...ShowData,data:data});
  
      }
	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",show_id);
		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [show_id]);



const changeEditMode=()=>{

	setRequestErrors({errors:null});
    if(ShowData.edit){
		setShowData({...ShowData,data:{...ShowData.orginal},edit:!ShowData.edit})
	}else{
	setShowData({...ShowData,edit:!ShowData.edit})
	}


	//setShowData({...ShowData,edit:!ShowData.edit})

};




const tryToSave=()=>{
	setRequestErrors({errors:null});
let url="shows/"+show_id+"/otherpersons";
	let data={otherpersons:[...ShowData.data.otherpersons]};
	

	data.otherpersons.sort((a,b) => a.order_persons - b.order_persons);


	apiCall.ADD(url, data,function(status,err_msgs=null){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			setShowData({...ShowData,"edit":false});
			setRequestErrors({errors:null});
			
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{

};

console.log("COMMON",state.common.loading);
	if(!ShowData.loaded ){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
  
    const changeInputValue=(name,value,index)=>{
        var data={...ShowData.data};
        var otherpersons=[...ShowData.data.otherpersons];
        otherpersons[index][name]=value;
        setShowData({...ShowData,data:data});





    };
    const changetitle=(locale,name,value,index)=>{
       
        var data={...ShowData.data};
        var otherpersons=[...ShowData.data.otherpersons];

        if(!otherpersons[index].texts[locale]){
           otherpersons[index].texts[locale]=new Object();
        }

        otherpersons[index].texts[locale][name]=value;

        setShowData({...ShowData,data:data});


    };
    //title","title",index,person.texts,false
    const getInput=(label,name,index,data,textarea=false)=>{
        if(!ShowData.edit){
			if(data.fi &&  data.fi[name]){
				return data.fi[name];
			}
            return null;
        }
		return <InputTextTranslations showlang={true} index={index} textarea={textarea}  name={name}  onFocus={onFocus}  locales={state.common.locales}  onChange={changetitle} data={data} edit={ShowData.edit}/>
; 
	};

	var persons = [...ShowData.data.otherpersons];
	persons.sort((a,b) => a.order_persons - b.order_persons);


const moveUp=(index)=>{


		var data={...ShowData.data};
        var otherpersons=[...ShowData.data.otherpersons];

		otherpersons.sort((a,b) => a.order_persons - b.order_persons);

		let temp = otherpersons[index];

		
		otherpersons[index] = otherpersons[index-1];
	  
		
		otherpersons[index-1] = temp;


        for(var i=0;i<otherpersons.length;i++){


			otherpersons[i].order_persons=i;


		}

		data.otherpersons=[...otherpersons];

 		setShowData({...ShowData,data:data});

	

};
const moveDown=(index)=>{
	var data={...ShowData.data};
	var otherpersons=[...ShowData.data.otherpersons];

	otherpersons.sort((a,b) => a.order_persons - b.order_persons);

	let temp = otherpersons[index];

	
	otherpersons[index] = otherpersons[index+1];
  
	
	otherpersons[index+1] = temp;


	for(var i=0;i<otherpersons.length;i++){


		otherpersons[i].order_persons=i;


	}

	data.otherpersons=[...otherpersons];

	 setShowData({...ShowData,data:data});


	};
    const rows=persons.map((person,index) =>{

        return <tr key={index}><td>

<InputText onFocus={onFocus}  index={index} errors={RequestErrors.errors}  onChange={changeInputValue} data={person} name="lastname" edit={ShowData.edit}/>

           </td>
            
            <td><InputText onFocus={onFocus}  index={index} errors={RequestErrors.errors}  onChange={changeInputValue} data={person} name="firstname" edit={ShowData.edit}/>
</td>

<td>{getInput("title","title",index,person.texts,false)}
</td>
<td>
	
	{(ShowData.edit && index!=0?<span className="move-up move-item" onClick={(e)=>(moveUp(index))}>▲</span>:null)}
	{(ShowData.edit && index+1!=persons.length?<span className="move-down move-item" onClick={(e)=>(moveDown(index))}>▼</span>:null)}
</td>


{ShowData.edit?<td><span class='remove-me' onClick={(e) => {
      				  remove(index);
   				 }}>Remove</span></td>
                    
                    
                    :null}
</tr>;
      
    });
	console.log("SHOW",ShowData);
	const getEventTopic=()=>{

		if(event_id){
			return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
		}
		return null;
	}



	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				<div className={(event_id!=null?"has-event":null)}>
				{getEventTopic()}
				<h2>{ShowData.data.texts.fi.name}</h2>

				{<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>}



					{ShowData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
</div>
					{event_id?<EventTopNav id={event_id} show_id={show_id}/>:null}
{show_id?<ShowTopNav  data={ShowData.data} errors={RequestErrors.errors} event_id={event_id} id={ShowData.data.id}/>:null}
	

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
                       


                        <table className="table-new fringetable"><thead><tr><th>Lastname/Artist name</th><th>Firstname</th><th>Title</th><th></th><th>{ShowData.edit?
						<div className="edit-btn" onClick={(e) => {
      				 addPerformer();
   				 }}><span>+</span></div>:null}
</th></tr></thead><tbody>{rows}</tbody></table>
                        </div>
					


					</div>
					<div className="form-block">

						
					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
