import React,{useState,useEffect,useRef} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import InputFile from "./form/InputFile.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import Period from "./form/Period.js";
import CustomDate from "./form/CustomDate.js";
import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import InputTextTranslations from "./form/InputTextTranslations.js";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import TopNav from "./TopNav.js";
import PersonRolesEditor from "./form/PersonRolesEditor.js";


import {
	browserHistory,
    useParams
  } from "react-router-dom";
import {
	
	
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



 
  export const defaultGenreData={
	
	
	texts:{},
    active:0,
	datetoadd:null,
	genres:[],
	orginal:null,
	loaded:false,
	edit:false
	
};

export const DefaultRequestErrors={

	errors:null
} 
export default function Sponsors() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
	



	const [IsStored, setIsStored] = useState(false);
    const [EventData, setEventData] = useState({
		...defaultGenreData
		
    });
	const [RequestErrors,setRequestErrors] = useState({
		...DefaultRequestErrors
	});

   	const apiCall = apiClient(state, dispatch);

	
const addNew=()=>{
	console.log(EventData.genres);
	setEventData({...EventData,genres:[{img:null,name:null},...EventData.genres]});

};
const remove=(index)=>{

	let genres=[...EventData.genres];
	genres=genres.filter((value, curindex) => index !== curindex); 


	setEventData({...EventData,genres:[...genres]});
};
    useEffect(() => {
	console.log("effect")
		fetch( config.apiUrl+"/sponsors",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventData({genres:[...data],orginal:JSON.parse(JSON.stringify(([...data]))),loaded:true}))

	  }, []);
	  


		const onFocus=(name,value,index)=>{
			let key=name;
		
			let errors={...RequestErrors.errors};
			errors[key]=null;
			setRequestErrors({errors:{...errors}});
		};


	
	
	
	const changeInputValue=(name,value,index)=>{
		

		let genres=[...EventData.genres];
		genres[index][name]=value;
		
		setEventData({...EventData,genres:[...genres]});




    };
	const  uploadFilesAndData=()=>{

		const formData = new FormData();

		var genres=[...EventData.genres];

		for(var i=0;i<genres.length;i++){

			formData.append(
				"file["+i+"]",
				(genres[i].img?genres[i].img:null)
			  );


			  formData.append("data["+i+"][name]",genres[i].name);
			  if(genres[i].id){
			  formData.append("data["+i+"][id]",genres[i].id);
			  }
			
				
		}
		
		console.log(formData);

		apiCall.ADD("sponsors", formData,function(status,err_msgs=null,v){
			if(status==400){
			 
			
  
		
			 
			
		  }else if(status==200){
		   
		var data=v.data;

		
		setEventData({genres:[...data],orginal:JSON.parse(JSON.stringify(([...data]))),edit:false,loaded:true});
		
			
		
  
  
		  }
  
	  }).then(() => {
		
		  
	  });




	/*	wished_index=0;
		setaddFilesState({...addFilesState,loading:true,errors:[]});
  
  
		console.log("STATE INDEX:",addFilesState.loading);
	  
		let index=0;
		  const formData = new FormData();
	  
		
			let loadIndex=wished_index;
	   
		   
			  formData.append(
				"file["+index+"]",
				addFilesState.files[loadIndex]
			  );
			
			  formData.append("data["+index+"][name]",addFilesState.data[loadIndex].name);
			  formData.append("data["+index+"][ean]",addFilesState.data[loadIndex].ean);
			  formData.append("data["+index+"][description]",addFilesState.data[loadIndex].description);
			  for(var i=0;i<addFilesState.data[index].folders.length;i++){
				
				formData.append("data["+index+"][folders][]",addFilesState.data[loadIndex].folders[i].id);
			 }
		
	  
		  apiCall.ADD("upload", formData,function(status,err_msgs=null){
			if(status==400){
			 
			  let a=[];
  
			  console.log("sate error",addFilesState);
  
			  setaddFilesState({...addFilesState,loading:false,errors:err_msgs});
			 
			
		  }else if(status==200){
		   
		
			console.log("FLIPPAAAAAA");
			let files=[...addFilesState.files];
			files.shift();
			let datat=[...addFilesState.data];
			datat.shift();
			console.log(addFilesState.files.shift(),addFilesState.files);
			console.log("SAIN VASTAUKSEN, wished oli",wished_index);
  
  
			setaddFilesState({...addFilesState,loading:true,files:[...files],data:[...datat]});
  
		
  
  
		  }
  
	  }).then(() => {
		
		  
	  });
  */
	};
	
	
	const tryToSave=()=>{

		uploadFilesAndData();

		
		return;
		let url="sponsors";
		let data={...EventData.genres};
		
		apiCall.ADD(url, {sponsors:data},function(status,err_msgs=null,v){
		
			if(status==400){

				console.log(err_msgs);
				console.log(RequestErrors);
			
				setRequestErrors({errors:{...err_msgs}});
	
			
			}else if(status==200){

				let data=v.data;
				setEventData({...EventData,edit:false,genres:[...data],orginal:JSON.parse(JSON.stringify(([...data]))),loaded:true});
				//setIsStored(false);
			}
	
		}).then(() => {
			//apiCall.GET('destinations');


			
			
		});
	
	
	
	
		
	};
	const addNewDate=(e)=>{

		setEventData({...EventData,dates:[...EventData.dates,EventData.datetoadd],datetoadd:null})
		
	}
	const addAnother=(e)=>{


    };
	if(IsStored){

		return (<div><div className="thanks"><h1>Event added</h1><p><a onClick={addAnother}>Lisää uusi</a></p></div></div>);
	}


	if(!state.common.locales){
		return null;
	}
	
	const changeEditMode=()=>{
		if(EventData.edit){

			console.log(EventData.orginal);
			let org=JSON.parse(JSON.stringify(([...EventData.orginal])))
			
			setEventData({...EventData,edit:false,genres:[...org]})
			//setEventData({genres:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

		}else{
		setEventData({...EventData,edit:!EventData.edit})
		}
	};



	const changeImage=(e,index)=>{


	
		let genres=[...EventData.genres];
		genres[index].img=e.target.files[0];

	
		setEventData({...EventData,genres:[...genres]});
	
       /* for(var i=0;i<e.target.files.length;i++){
         

          datat.push({name:e.target.files[i].name,ean:"",description:"",folders:[]});
          console.log("KUVIAA",e.target.files.length);
          files.push(e.target.files[i]);
       
        }
        console.log(e.target.files);
        setaddFilesState({ files:files,data:datat,loading:false,errors:[]});*/














	};

	let REFS=[];

	const changeImg=(name,e,index)=>{

		console.log("ggg",e.target.files[0]);
		let genres=[...EventData.genres];
		console.log(genres,index);
		genres[index].img=e.target.files[0];
		setEventData({...EventData,genres:[...genres]});
		/**/
	};	
	
	const rows=EventData.genres.map((genre,index) =>{
	
        return <div className="tr">
			{EventData.edit?
			<div className="td sponsor-img">
				{genre.thumb && !genre.img?<img src={config.apiUrl+""+genre.thumb} />:null}
				
				{!genre.img?<InputFile accept="image/png, image/gif, image/jpeg" index={index} edit={EventData.edit} name="file" onChange={changeImg}/>:genre.img.name}
		
		
		</div>:genre.thumb?	<div className="td sponsor-img"><img src={config.apiUrl+""+genre.thumb} /></div>:null}
		
		
		<div className="td"><InputText
onFocus={onFocus} 

 label=""
 
 remove={EventData.edit?remove:null}
  onChange={changeInputValue} 
  data={genre} index={index} name="name" edit={EventData.edit}/>
  </div>

  
  
  </div>;
      
    });
	/*<td>{EventData.edit?<span class='remove-me' onClick={(e) => {
      				  remove(index);
   				 }}>Poista</span>:null}</td>*/ 

					
	return (
		
		<div className="mycontent events-content">
		
				<div className="inner">
				<div className="box">

			
		<h2>Sponsors</h2>{<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>}
				

			
<div className="form-blocks">


					<div className="form-block">



<div class="wide">
                       


                        <div className="table-div">
							<div className="tr">
							
							<div className="td td-locale">Name</div>
								<div className="td">{EventData.edit?
						<div className="edit-btn" onClick={(e) => {
							addNew();
   				 }}><span>+</span></div>:null}
</div></div>{rows}</div>
                        </div>
					






					
						
						



					

					</div>
				
					{EventData.edit?<div className="btn-block">
					<div className="save-btn" onClick={tryToSave}><span>Save</span></div>

					</div>:null}
					

					
				</div></div>
					
			</div>
			
			
		</div>
		
	);
}
