import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Period from "./form/Period.js";
import Notify from "./Notify.js";


import {format} from "../commonFunctions";

import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
  export const defaultLaskentaData = {
	loading:true,
	data:[],
	period:null
};
export default function Palkanlaskenta() {
	const { state, dispatch } = useStore();
	const {common}=state;
  
	console.log("UI",state.ui);


	const [LaskentaData, setLaskentaData] = useState({
		...defaultLaskentaData
		
    });







	const apiResponse=(data)=>{

		

	
		
	
	
		if(data){
			
			
			
			setLaskentaData({...LaskentaData,data:[...data]});
			
		}


	  };
	  const getPeriod=()=>{

		
		if(common.current_period==null){ return null; }




		if(state.ui.period==null){
			return common.current_period;


		}

		return state.ui.period;

	  }

const period=getPeriod();
	useEffect(() => {
			
	
		if(period==null){ return; }
		

	
		console.log("QUERY",period);
		var url="/listwages/"+period;

		fetch( config.apiUrl+url,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data =>apiResponse(data))

	 
		

	  }, [period]);
	
	if(!state.users.items){
		return null;
	}
	const getPalkkaLink=(item)=>{

		if(item.wages.length==0){

			return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Aloita laskenta</Link>
		}


		if(item.wages.length==1){
			if(item.wages[0].confirmed===1){
				return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Valmis</Link>
			}else{

				
				return <Link className="arrow-right" to={"/palkanlaskenta/"+item.person.id+"/"+period} >Kesken <Notify errors={item.wages[0].calculation.errors.value}/></Link>
			}
			
		}
		return  null;
	}
	const getVar=(item,key)=>{
		if(item.confirmed===1){


			return (item[key]!==0?format(item[key]):"-");


		}else{

			return format(item.calculation[key].value);
		}

		
	}
	const getRow=(item,index)=>{
console.log("GETROW",item.role);
		return   <tr key={index}><td>{item.person.lastname} {item.person.firstname}</td>
		
		<td>{item.role ?item.role.name:"Ei määritetty"}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"brutto"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"nettopalkka"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"maksetaan"):"-")}</td>

		<td>{getPalkkaLink(item)}</td>
		</tr>;

	};
	const getCompany=(company,index)=>{
		let data = company.map((item,index) =>
		getRow(item,index)
		);

		return (<tbody className="table-new">
			
			
			<tr class="company-header"><th colSpan="6">{company[0].person.department.name}</th></tr>
			<tr class="field-header"><th>Nimi</th><th>Rooli</th><th>Brutto</th><th>Netto</th><th>Maksetaan</th><th>Status</th></tr>
			
			
			<tbody>
			
				{data}
					
			</tbody>		
			
			
		</tbody>);


	
	}
	const listItems = LaskentaData.data.map((item,index) =>
		getRow(item,index)
	);


	const changePeriod=(d,v)=>{

		dispatch({ type: "CHANGE_PERIOD", period: v });
		//setLaskentaData({...LaskentaData,period:v});
		
		
	};	

	if(!period){

		return (<div>Ladataan</div>);
	}
	if(!state.companies.companies){

		return null;
	}
	console.log("DATANI",LaskentaData.data);
	/*let companies=[];
	for(var i=0;i<LaskentaData.data.length;i++){
		console.log(LaskentaData.data[i]);
		if(companies[LaskentaData.data[i].person.department_id]==null){
			companies[LaskentaData.data[i].person.department_id]=[];
		}
		companies[LaskentaData.data[i].person.department_id].push(LaskentaData.data[i]);
		
	}
	companies = companies.filter(function (el) {
		return el != null;
	  });
	  const listItems2 = companies.map((item,index) =>
		getCompany(item,index)
	);*/

	
	const headerKeys=[
		["lastname","Nimi"],
		["role","Rooli"],
		
		[null,"Brutto"],
		[null,"Netto"],
		[null,"Maksetaan"],
		[null,"Status"]
	];
	const setOrdering=(e)=>{


	};
	const getSorting=(a,b)=>{


		return  0;
		let av=0;
		let bv=0;
		if(state.common.sortIndexPalkanlaskenta=="role"){


		if(a.role){

			av= a.role.name;
		}else{

			av="-";
		}
		if(b.role){

			bv= b.role.name;
		}else{

			bv="-";
		}


		}else{

			if(state.common.sortIndexPalkanlaskenta=="lastname"){
				av= a[state.common.sortIndexPalkanlaskenta].toUpperCase();
				bv=b[state.common.sortIndexPalkanlaskenta].toUpperCase();

			}
		
		}
		
		
		
			let v= av> bv ? 1 : -1;
			return v*(state.common.sortIndexMode==="asc"?1:-1);
	

		
		//return a[state.common.sortIndexPersons].toUpperCase() > b[state.common.sortIndexPersons].toUpperCase() ? -1 : 1;
		

	};
	const getPalkat=(company_id)=>{
		return LaskentaData.data.sort((a, b) => getSorting(a,b)).filter(item => item.person.department_id===company_id).map((item,index) =>



		 <tr className="data-row" key={index}><td>{item.person.lastname} {item.person.firstname}</td>
		
		<td>{item.role ?item.role.name:<span className="error-wage">Ei määritetty</span>}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"brutto"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"nettopalkka"):"-")}</td>
		<td>{(item.wages.length!==0?getVar(item.wages[0],"maksetaan"):"-")}</td>

		<td>{(item.role?getPalkkaLink(item):<Link   className="error-wage" to={"/persons/"+item.person.id+"/roles"}>Aseta rooli</Link>)}</td>
		</tr>



		);


		




	};
	const getHeader=()=>{

		return headerKeys.map((item,index) =>

		<th onClick={(e) => {
			setOrdering(item[0]);
		}} className={item[0]?"orderable "+(item[0]===state.common.sortIndexPersons?"order-active "+state.common.sortIndexMode:null):null} key={index}>{item[1]}</th>
		
		
		  );


	};

	const userHeader=<tr className="text-header">{getHeader()}</tr>;

	const departments=state.companies.companies.map((item) =>
		

		<tbody key={item.id} className={item.style}>
			<tr className="header"><th colSpan={headerKeys.length}>
				
				<h2><span className="small-info">Yritys</span>{item.name}</h2>
				
				
				</th></tr>
			{userHeader}
			{getPalkat(item.id)}
			
		</tbody>


	  );

console.log("state.common.min_wage_period",common)

	return (
		<div className="mycontent">
			
			<div className="inner">
				<div className="box">
				<div className='period-topic'>
					<h2>Palkanlaskenta</h2><Period min={state.common.min_wage_period} max={state.common.max_wage_period} onChange={changePeriod} name="period"  value={period} edit={true}/>
					</div>
					<table className="table-new">
						{departments}
			
			</table>
					
		</div>
		</div></div>		
	);
}
