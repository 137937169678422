import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import Period from "./form/Period.js";
import CustomDate from "./form/CustomDate.js";
import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import InputTextTranslations from "./form/InputTextTranslations.js";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import TopNav from "./TopNav.js";
import PersonRolesEditor from "./form/PersonRolesEditor.js";


import {
	browserHistory,
    useParams
  } from "react-router-dom";
import {
	
	
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



 
  export const defaultGenreData={
	
	
	texts:{},
    active:0,
	datetoadd:null,
	genres:[],
	orginal:null,
	loaded:false,
	edit:false
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function Categories() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
	



	const [IsStored, setIsStored] = useState(false);
    const [EventData, setEventData] = useState({
		...defaultGenreData
		
    });
	const [RequestErrors,setRequestErrors] = useState({
		...DefaultRequestErrors
	});

   	const apiCall = apiClient(state, dispatch);

	
const addNew=()=>{
	console.log(EventData.genres);
	setEventData({...EventData,genres:[{},...EventData.genres]});

};
const remove=(index)=>{

	let genres=[...EventData.genres];
	genres=genres.filter((value, curindex) => index !== curindex); 


	setEventData({...EventData,genres:[...genres]});
};
    useEffect(() => {
	console.log("effect")
		fetch( config.apiUrl+"/categories",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventData({genres:[...data],orginal:JSON.parse(JSON.stringify(([...data]))),loaded:true}))

	  }, []);
	  


		const onFocus=(name,value,index)=>{
			let key=name;
		
			let errors={...RequestErrors.errors};
			errors[key]=null;
			setRequestErrors({errors:{...errors}});
		};


	
	
	
	const changeInputValue=(locale,name,value,index)=>{
		let texts={...EventData.genres[index].texts}
		
		if(texts[locale]==null){
			texts[locale]={};
			
		}	
		
		console.log(locale,name,value,index);
		texts[locale][name]=value;

		let genres=[...EventData.genres];
		genres[index].texts={...texts};
		setEventData({...EventData,genres:[...genres]});




    };

	
	
	const tryToSave=()=>{

		let url="categories";
		let data={...EventData.genres};
		
		apiCall.ADD(url, {categories:data},function(status,err_msgs=null,v){
		
			if(status==400){

				console.log(err_msgs);
				console.log(RequestErrors);
			
				setRequestErrors({errors:{...err_msgs}});
	
			
			}else if(status==200){

				let data=v.data;
				setEventData({...EventData,edit:false,genres:[...data],orginal:JSON.parse(JSON.stringify(([...data]))),loaded:true});
				//setIsStored(false);
				apiCall.GET("common");
			}
	
		}).then(() => {
			//apiCall.GET('destinations');


			
			
		});
	
	
	
	
		
	};
	const addNewDate=(e)=>{

		setEventData({...EventData,dates:[...EventData.dates,EventData.datetoadd],datetoadd:null})
		
	}
	const addAnother=(e)=>{


    };
	if(IsStored){

		return (<div><div className="thanks"><h1>Event added</h1><p><a onClick={addAnother}>Lisää uusi</a></p></div></div>);
	}


	if(!state.common.locales){
		return null;
	}
	
	const changeEditMode=()=>{
		if(EventData.edit){

			console.log(EventData.orginal);
			let org=JSON.parse(JSON.stringify(([...EventData.orginal])))
			
			setEventData({...EventData,edit:false,genres:[...org]})
			//setEventData({genres:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

		}else{
		setEventData({...EventData,edit:!EventData.edit})
		}
	};


const locale_header=state.common.locales.map((locale)=>{
	return (<div className="td td-locale">{locale}</div>);
});

	const rows=EventData.genres.map((genre,index) =>{

        return <InputTextTranslations 
onFocus={onFocus} 
 locales={state.common.locales} 
 label=""
 
 remove={EventData.edit?remove:null}
  onChange={changeInputValue} 
  data={genre.texts} index={index} name="name" edit={EventData.edit}/>;
      
    });
	/*<td>{EventData.edit?<span class='remove-me' onClick={(e) => {
      				  remove(index);
   				 }}>Poista</span>:null}</td>*/ 

					
	return (
		
		<div className="mycontent events-content">
		
				<div className="inner">
				<div className="box">

			
		<h2>Types</h2>{<div className={"edit-btn "+(EventData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>}
				

			
<div className="form-blocks">


					<div className="form-block">



<div class="wide">
                       


                        <div className="table-div">
							<div className="tr">
								{locale_header}
								<div className="td">{EventData.edit?
						<div className="edit-btn" onClick={(e) => {
							addNew();
   				 }}><span>+</span></div>:null}
</div></div>{rows}</div>
                        </div>
					






					
						
						



					

					</div>
				
					{EventData.edit?<div className="btn-block">
					<div className="save-btn" onClick={tryToSave}><span>Save</span></div>

					</div>:null}
					

					
				</div></div>
					
			</div>
			
			
		</div>
		
	);
}
