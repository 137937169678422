import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import {format} from "../commonFunctions";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";
/*autoetu: {label: "Autoetu", value: 200, type: "luontoisetu"}
bonus: {label: "Oma Bonus", value: 950, type: "palkka"}
bonus_alaiset: {label: "Bonus omista  alaisista", value: 1500, type: "palkka"}
bonus_ryhma: {label: "Bonus alaisista (ryhmä)", value: 2000, type: "palkka"}
brutto: 7354.900000000001
duell_laskup: {label: "Duell lasku p", value: -125, type: "palkka"}
ennakkoprovisio: {label: "Ennakkoprovisio", value: -0, type: "palkka"}
ennakonpidatyksenalainen: 7714.500000000001
ennakonpidatys: 2661.5025
ilmoitettu_myynti: {label: "Ilmoitettu myynti", value: 19955, type: "variable"}
kaikki_yhteensa: 8652.1
kilometrit: {label: "Kilometrikorvaus", description: "4992 km x 0.1 €/km", value: 499.20000000000005, type: "kulukorvaus"}
koulutuskilometrikorvaus: {label: "Koulutus km korvaus", description: "74 km x á 0.15 €)", value: 11.1, type: "palkka"}
koulutuskorvaus: {label: "Korvaus koulutuspäivistä", description: "3 kpl x á 280 €)", value: 840, type: "palkka"}
kulukorvaukset_yhteensa: 1297.2
laskutettu_myynti: {label: "Laskutettu myynti", value: 19955, type: "variable"}
lounasetu: {label: "Lounassetelit", description: "20 kpl*6.98 €", value: 139.60000000000002, type: "luontoisetu"}
maksetaan: 4854.151250000001
netto_vahennykset: (3) [{…}, {…}, {…}]
nettopalkka: 4056.951250000001
paivarahat: {label: "Päivärahat", description: "19kpl x 42 €", value: 798, type: "kulukorvaus"}
pohjapalkka: {label: "Pohjapalkka ilman rajaa", value: 1000, type: "palkka"}
provisio: {label: "Provisio", value: 2476, type: "palkka"}
puhelinetu: {label: "Puhelinetu", value: 20, type: "luontoisetu"}
tyel: 489.87075000000004
tyottomyysvakuutusmaksu: 146.5755*/ 
export default function PreviewNettoVahennykset(props) {
	const { state, dispatch } = useStore();

    const [open,setOpen]=useState(false);

    const toggleOpen=()=>{
  
      
      setOpen(!open);
    };

   

	
    const items= props.data.map((item,index) =>{

        return (<tr    key={index} className={item.is_positive===0?"negative":null}><td>{item.label}</td><td  className="amount">{item.is_positive===0?"-":null} {format(item.value)} €</td></tr>:null);
      
      });

      let sum=0.0;
        for(var i in props.data){

            

                        sum+=props.data[i].value;
            

        }
       


	return (
		<div className=" preview">

			{props.topic?<h4 className={(open?"arrow open":"arrow closed")}  onClick={toggleOpen}>{props.topic}  <span className="total">{format(sum)} €</span></h4>:null}
            {open?
			<table className="table">
                <thead>

                    <tr><th>Selite</th><th>Määrä</th></tr>
                </thead>
                <tbody>

                    {items}
                   
                    <tr className="total-row "><td>Yhteensä</td><td className="amount"> {format(sum)} €</td></tr>

                </tbody>

            </table>
            :null}
			
		</div>
	);
}
