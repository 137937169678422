import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import WageBlock from "./form/WageBlock.js";
import { apiClient } from "../apiCall";
import WageTable from "./form/WageTable.js"
import WageBlockNettoVahennykset from "./form/WageBlockNettoVahennykset.js";
import {format,formatSeason} from "../commonFunctions";


import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link,
    Nav,NavLink
	// etc.
  } from "react-router-dom";

export const defaultData = {
	loading:true,
	simple:true
	
};
export default function WageDisplay(props) {
	const { state, dispatch } = useStore();

	const apiCall = apiClient(state, dispatch);		

	
	const [Data, setData] = useState({
		...defaultData
		
    });
	useEffect(() => {

console.log("USE thisisisisisisis",props.id)
		let url="wages/"+props.id+"/confirm";
		///let data={...WageData};
		if(props.id!==null){
		apiCall.GET_RAW(url, function(status,err_msgs=null,response=null){

		
			if(status==400){
		
			
			}else if(status==200){
				


				setData({...response.data,loading:false,simple:true});
			}




		});

		}else{
			console.log("PROPS.data",props.data)
			setData({...props.data,loading:false,simple:props.simple});
			
		}
	

	  }, [props.id,props.data]);
	

/*//setWageData({...WageData,"storing":true});
		

		let url="wages/"+data.id+"/preview";
		///let data={...WageData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null,response=null){
				
				
				if(status==400){
		
					//setFolderData({...FolderData,errors:err_msgs})
				}else if(status==200){
					console.log("CALC DATA",response.data);



					setResultData({...ResultData,calculation:{...response.data}});
				}
		
			}).then(() => {
				//apiCall.GET('destinations');
				
			});
		
		
*/ 

console.log("SAATU DATA",Data);



if(Data.loading){
	return (<div className="paywage paywage-set confirm">Ladataan</div>);
	
}	
console.log(Data);




const rows=Data.palkkalajit.map((item, index) => {

	return <tr className="item" key={index}>
		<td>{item.palkkalaji}</td>
		<td>{item.description}</td>
		<td>{item.lmk}</td>
		<td>{format(item.avalue)} €</td>
		<td>{item.kerroin}</td>
		<td>{format(item.total)} €</td>
		</tr>
  
  
});

const rowslisatietoja=Data.lisatietoja.map((item, index) => {

	return <tr className="item" key={index}>
		<td>{item.name}</td>
		<td className={(item.strong===true?"strong":null)}>{format(item.season)} €</td>
		<td>{format(item.start_of_season)} €</td>
	
		</tr>
  
  
});

const address_rows=Data.address_rows.map((item, index) => {

	return <span key={index}>
		{item}
	
		</span>
  
  
});
const info_rows=Data.info_rows.map((item, index) => {

	return <p key={index}>
		<strong>{item[0]}</strong>
		<span>{item[1]}</span>
		</p>
  
  
});


const vasen_rows=Data.yhteenveto_vasen.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(parseFloat(item.value))}</td>
		</tr>
  
  
});
const vasen_alarows=Data.yhteenveto_alavasen.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(item.value)}</td>
		</tr>
  
  
});
const oikea_alarows=Data.yhteenveto_alaoikea.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(item.value)}</td>
		</tr>
  
  
});

const oikea_rows=Data.yhteenveto_oikea.map((item, index) => {

	return <tr key={index}>
		<th>{item.name}</th>
		<td>{format(parseFloat(item.value))}</td>
		</tr>
  
  
});


const swap=()=>{

	setData({...Data,simple:!Data.simple});

};




return (<div className="wage-display-holder">
{props.id!==null?
	<ul className="palkka-nav"><li className={(Data.simple?"active":null)}><span  onClick={swap} >Yhteenveto</span></li><li  className={(!Data.simple?"active":null)}><span  onClick={swap} >Palkkalaskelma</span></li>
	
	{props.print?<li className="li-print"><span onClick={(e)=>{

window.print();
	}}>Tulosta</span></li>:null}
	</ul>
	:null}
	
	{Data.simple?
<table className="yhteenveto-all">

	<tr>
		<td>
		<table className="yhteenveto">
				
				<tbody>{vasen_rows}</tbody>

		</table>
		</td>
		<td>
		<table className="yhteenveto">
				
				<tbody>{oikea_rows}</tbody>

		</table>

		</td>
	</tr>
	<tr>
		<td>
				<table className="yhteenveto">
				<tbody>{vasen_alarows}</tbody>

				</table>
		</td>
		<td>
		<table className="yhteenveto">
		<tbody>{oikea_alarows}</tbody>

		</table>
		</td>
	</tr>
	</table>:null
}
{!Data.simple?
	


		<table className="palkkalaskelma">
		<tr>
			<td colSpan="2" className="address_rows"><div>{address_rows}</div></td>	
			<td colSpan="4" className="info_rows">{info_rows}</td>
		</tr>
		<tr className="border"><td colSpan="6"></td></tr>	
	
			<tr className="header-row-palkkalajit"><th>Palkkalaji</th><th>Selite</th><th>Lkm</th><th>á- hinta</th><th>Kerroin</th><th></th></tr>
		
		<tbody>{rows}</tbody>
		
		
	
	
		
		<tbody>
			<tr className="header-row-lisatietoja"><th colSpan="3">Lisätietoja</th></tr>
			<tr className="header-row"><th></th><th>Kauden alusta</th><th>Vuoden alusta</th></tr>{rowslisatietoja}</tbody></table>
		

:null}<div className="buttons">


{props.store?<button onClick={props.store}>Vahvista ja siirrä maksettaviin</button>:null}
</div>


		</div>);












/*



 
  if(Data.simple==false){




		const keys=[

			
			"autoetu",
			"puhelinetu",
			"lounasetu",

			"tyel",
			"kuukausipalkka",
			"puolipaivarahat",
			"paivarahat",
			"kilometrit",
			"koulutuskilometrikorvaus",
			"koulutuskorvaus",
			"sairasloma",
"lomapalkka","lomakorvaus"

		];

		return (<div><WageTable data={Data} keys={keys}/>
		</div>);

  }else{

 
	return (
		<div className="paywage paywage-set confirm">

			{props.topic?<h2>{props.topic}</h2>:null}
        	<div className="left">
				<WageBlock data={Data} keys={["ilmoitettu_myynti","provisio","ennakkoprovisio","sairasloma","lomapalkka","lomakorvaus"]}/>
				<WageBlock data={Data} keys={["koulutuskilometrikorvaus","koulutuskorvaus","autoetu","puhelinetu","puolipaivarahat","paivarahat"]}/>
				
			</div>
			<div className="right">
			<WageBlock data={Data} keys={["laskutettu_myynti","bonus","bonus_0","pohjapalkka","alaisbonuksetyhteensa"]}/>
			
			
			<div className="props">{additionsandsubs}</div>
			<WageBlock data={Data} keys={["kaikki_yhteensa"]}/>
			<WageBlock data={Data} keys={["lounasetu","duell_laskup","paivarahat","kilometrit","kulukorvaukset_yhteensa"]}/>
			
			
			</div>
			<div className="bottom">
			<div className="left">

			
			<WageBlockNettoVahennykset data={Data.netto_vahennykset} />

			<WageBlock data={Data} keys={["tax_rate","brutto","maksetaan"]}/>


			

				
			
			</div>
			<div className="right">
			<WageBlock data={Data} keys={["addional_tax_rate","nettopalkka","ennakonpidatys"]}/>
			<WageBlock data={Data} keys={["ulosotto","yrityslainat_yhteensa"]}/>

			</div>

			</div>
			<div className="buttons">


					{props.store?<button onClick={props.store}>Vahvista ja siirrä maksettaviin</button>:null}
	</div>
		</div>
	);
}*/ }
