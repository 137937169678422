import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";


import InputDouble from "./form/InputDouble.js";
import InputText from "./form/InputText.js";


import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import ShowTopNav from "./ShowTopNav.js";

import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultPlaceData={

	loaded:false,
	data:{texts:null,lat:null,lng:null,google_maps_url:null},
	orginal:null,
    edit:false
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function Show(props) {
	const { state, dispatch } = useStore();
    const { place_id } = useParams();
  
    const [PlaceData, setPlaceData] = useState({
		...defaultPlaceData
		
    });

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {

		
		if(place_id==null || place_id==undefined){
            setPlaceData({...PlaceData,edit:true,loaded:true});
        }else{
		fetch( config.apiUrl+"/places/"+place_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setPlaceData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

        }
        }, [place_id]);



const changeEditMode=()=>{
	if(PlaceData.edit){
		setPlaceData({...PlaceData,data:{...PlaceData.orginal},edit:!PlaceData.edit})
	}else{
	setPlaceData({...PlaceData,edit:!PlaceData.edit})
	}
};




const tryToSave=()=>{
    let url="places";

    if(place_id){
        url+="/"+place_id;
    }

	let data={...PlaceData.data};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			let data=v.data;
			
			if(place_id){
				setPlaceData({...PlaceData,"edit":false,data:{...data},orginal:JSON.parse(JSON.stringify((data)))});

			}else{
				
				//setPlaceData({...defaultPlaceData,loaded:true});
				if(props.stored){
					
					props.stored(data);
				}
			}
			//setPlaceData({...PlaceData,"edit":false,data:{...data},orginal:JSON.parse(JSON.stringify((data)))});
			//setRequestErrors({errors:null});
			//apiCall.GET("users");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};

console.log("COMMON",state.common.loading);
	if(!PlaceData.loaded ){

		return (<div></div>);
	} 
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
    const changePlainInputValue=(name,value)=>{

        let data={...PlaceData.data}
        data[name]=value;
        setPlaceData({...PlaceData,data:{...data}});
    };
    const changeInputValue=(locale,name,value)=>{
		let texts={...PlaceData.data.texts}

        if(!texts[locale]){
            texts[locale]=new Object();
        }
		texts[locale][name]=value;
		setPlaceData({...PlaceData,data:{...PlaceData.data,texts:texts}});




    };

	console.log("SHOW",PlaceData);
	const getInput=(label,name,textarea=false)=>{

		return <InputTextTranslations textarea={textarea} label={label} name={name}  onFocus={onFocus}  locales={state.common.locales}  onChange={changeInputValue} data={PlaceData.data.texts} edit={PlaceData.edit}/>
; 
	};
	console.log("DTEXTS",PlaceData.data.texts);


	const locale_header=state.common.locales.map((locale)=>{
		return (<div className="td td-locale">{locale}</div>);
	});


	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">

				{place_id? <h2>{PlaceData.data.texts.fi.name}</h2>:null}
                
               

				{place_id? <div className={"edit-btn "+(PlaceData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Edit</span></div>:null}



					{PlaceData.edit && place_id?<div className="save-btn" onClick={tryToSave}><span>Save</span></div>:null}


					

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
 							<div className="table-div">
								<div className="tr">
									<div className="td td-locale"></div>{locale_header}
								</div>
								{getInput("Venue *","name")}
								{getInput("Stage","subname")}
                                {getInput("Description","shortdescription",true)}
                                {getInput("Additional information","additionalinfo",true)}


								
							
                           
                            <div className="tr">

                                <div className="td td-th">Latitude</div>
                                <div className="td">
                                  <InputDouble onFocus={onFocus} errors={RequestErrors.errors} onChange={changePlainInputValue} data={PlaceData.data} name="lat" edit={PlaceData.edit}/>
                                </div>
                            </div>
                            <div className="tr">

                                <div className="td td-th">Longitude</div>
                                <div className="td">
                                  <InputDouble onFocus={onFocus} errors={RequestErrors.errors} onChange={changePlainInputValue} data={PlaceData.data} name="lng" edit={PlaceData.edit}/>
                                </div>
                            </div>

                            <div className="tr">

                                <div className="td td-th">Maps url</div>
                                <div className="td">
                                  <InputText onFocus={onFocus} errors={RequestErrors.errors} onChange={changePlainInputValue} data={PlaceData.data} name="google_maps_url" edit={PlaceData.edit}/>
                                </div>
                            </div>
                           
                           
                            
                            {!place_id?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
                            </div>
                       
                        </div>
						




					</div>
				
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
