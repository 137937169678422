import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import InputFile from "./form/InputFile.js";

import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import InputBoolean from "./form/InputBoolean.js";
import EventTopNav from "./EventTopNav.js";

import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import ShowTopNav from "./ShowTopNav.js";

import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";
import Show from "./Place";


  
  export const defaultShowData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};


export const DefaultRequestErrors={

	errors:null
}
export default function ShowImages() {
	const { state, dispatch } = useStore();
    const { show_id,event_id } = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });
  

	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	

	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",show_id);
		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [show_id]);

     

const changeEditMode=()=>{
	if(ShowData.edit){
		setShowData({...ShowData,data:{...ShowData.orginal},edit:!ShowData.edit})
	}else{
	setShowData({...ShowData,edit:!ShowData.edit})
	}
};

const getEventTopic=()=>{

	if(event_id){
		return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
	}
	return null;
}


const tryToSave=()=>{

let url="shows/"+show_id+"/images";
const formData = new FormData();

		var images=[...ShowData.data.images];
		var c=0;
		var c2=0;
		for(var i=0;i<images.length;i++){
			console.log("ARVO",images[i].id);
			if(images[i].isnew){
				formData.append(
					"file["+c+"]",
					images[i].file
				);


				formData.append("filedata["+c+"][name]",(images[i].file?images[i].file.name:null));
				
				formData.append("filedata["+c+"][order]",(images[i].file?images[i].order:null));
				

				c++;
			
			}else{

				formData.append("old["+c2+"][order]",images[i].order);
				formData.append("old["+c2+"][id]",images[i].id);
				c2++;

			}
		}
		
		console.log(formData);

		apiCall.ADD(url, formData,function(status,err_msgs=null,v){
			if(status==400){
			 
			
  
		
			 
			
		  }else if(status==200){
		   
		var data=v.data;

		
		setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true,edit:false});
			
		
  
  
		  }
  
	  }).then(() => {
		
		  
	  });

    




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};

console.log("COMMON",state.common.loading);
	if(!ShowData.loaded){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
  
    const changeInputValue=(locale,name,value)=>{
		let texts={...ShowData.data.texts}
		texts[locale][name]=value;
		setShowData({...ShowData,data:{...ShowData.data,texts:texts}});




    };
   
	console.log("DTEXTS",ShowData.data.texts);


	const addNewImage=()=>{

		let imgs=[...ShowData.data.images];
		for(var i=0;i<imgs.length;i++){
			imgs[i].order++;
		}

		let images=[{file:null,isnew:true,order:0},...imgs];

		setShowData({...ShowData,data:{...ShowData.data,images:images}});


	};
	const remove=(index)=>{

		let images=[...ShowData.data.images];
		images=images.filter((value, curindex) => index !== curindex); 
	
		images.sort((a, b) => a.order > b.order ? 1 : -1);

		for(var i=0;i<images.length;i++){
			images[i].order=i;
		}
		setShowData({...ShowData,data:{...ShowData.data,images:images}});
	};
	const changeImg=(name,e,index)=>{

		let images=[...ShowData.data.images];
		images[index].file=e.target.files[0];
		setShowData({...ShowData,data:{...ShowData.data,images:images}});

	/*	console.log("ggg",e.target.files[0]);
		let genres=[...EventData.genres];
		console.log(genres,index);
		genres[index].img=e.target.files[0];
		setEventData({...EventData,genres:[...genres]});
		*/
	};	
	const move=(index,way)=>{
		let imgs=[...ShowData.data.images];

		if(way>0){

			
			imgs[index].order++;
			imgs[index+1].order--;

			
		}else{
			imgs[index].order--;
			imgs[index-1].order++;


		}
	
	
		
		imgs.sort((a, b) => a.order > b.order ? 1 : -1);
		setShowData({...ShowData,data:{...ShowData.data,images:[...imgs]}});
		

	};
    const images=ShowData.data.images.map((genre,index)=>{

		return <tr>
			<td>
			{genre.thumb?<a href={config.apiUrl+""+genre.orginal} target="_blank"><img className="show-image" src={config.apiUrl+""+genre.thumb}/></a>:null}
			{!genre.id && !genre.file?<InputFile accept="image/png, image/gif, image/jpeg" index={index} edit={ShowData.edit} name="file" onChange={changeImg}/>:(genre.file?genre.file.name:null)}
			</td>


			{ShowData.edit?<td><span className="remove-me"  onClick={(e) => {
      				  remove(index);
   				 }}>Remove</span></td>:null}


{ShowData.edit?<td className="moves">
	{index!=0?<span className="move move-up"  onClick={(e) => {move(index,-1)}}>▲</span>:null}
	{(index+1)!=ShowData.data.images.length?<span className="move move-down"  onClick={(e) => {move(index,1)}}>▼</span>:null}
	</td>:null}
			</tr>
	});
	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">
				<div className={(event_id!=null?"has-event":null)}>
				{getEventTopic()}
				<h2>{ShowData.data.texts.fi.name}</h2>

				{<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>}


{ShowData.edit?
				<div className={"edit-btn"} onClick={(e) => {
      				  addNewImage(e);
   				 }}><span>Add new</span></div>:null}
					{ShowData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}
</div>
					{event_id?<EventTopNav id={event_id} show_id={show_id}/>:null}
{show_id?<ShowTopNav  data={ShowData.data} errors={RequestErrors.errors} event_id={event_id} id={ShowData.data.id}/>:null}
					

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
 							<table>
								
									
                                {images}
							</table>
                       
                        </div>
						




					</div>
				
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
