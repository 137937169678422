import React,{useState,useEffect} from "react";
import MedicLogo from '../icons/medic.svg';

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import InputDouble from "./form/InputDouble.js";
import TopNav from "./TopNav.js";
import Place from "./Place.js";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";


  export const defaultEventsData={

	loaded:false,
	
	data:[],
	addnew:false
	
};


export default function Places() {
	const { state, dispatch } = useStore();

	const [EventsData, setEventsData] = useState({
		...defaultEventsData
		
    });

	useEffect(() => {
		fetch( config.apiUrl+"/places",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })}).then((res) => res.json())
		  .then(data => setEventsData({data:[...data],loaded:true}))

	  }, []);

	  console.log(EventsData.data);
	  const newlist=(d)=>{

		setEventsData({...EventsData,data:[...d],addnew:false})
	  }
 const changeAddMode=()=>{

	
		setEventsData({...EventsData,addnew:!EventsData.addnew})

 };
		const rows=EventsData.data.map((event,index) =>{

			return <tr key={index}>
                <td><Link to={"/places/"+event.id}>{event.texts.en.name}</Link></td>
				<td><Link to={"/places/"+event.id}>{event.texts.en.subname}</Link></td>
                <td><Link to={"/places/"+event.id}>{event.lat}</Link></td>
                <td><Link to={"/places/"+event.id}>{event.lng}</Link></td>
                <td><Link to={"/places/"+event.id}>{event.google_maps_url?"Yes":"-"}</Link></td>
                </tr>;
		  
		});
	return (
		<div className="mycontent events-content">
			
			<div className="inner">
				
				<div className="box">

					<h2>Places</h2>
					
					<div className={"edit-btn "+(EventsData.addnew?"active":"")} onClick={(e) => {
      				  changeAddMode(e);
   				 }}><span>Add new</span></div>
				
					<div className="form-blocks">
					<div className="form-block">
					{EventsData.addnew?<Place stored={newlist}/>:null}



					{!EventsData.addnew?<table className="table-new fringetable has-links">
				
					<thead><tr><th>Name</th><th>Stage</th><th>Latitude</th><th>Longitude</th><th>Google maps</th></tr></thead>
			
					<tbody>
						{rows}
					</tbody>
				
				
				
			</table>:null}</div></div></div>
		</div></div>		
	);
}
