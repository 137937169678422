


export default function InputSelect(props) {

    const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;
      
        props.onChange(name,value,props.index);
     
	};

  
    if(!props.options){
return null;
    }

let empty=null;
if(props.zero){

    empty=<option key="0" value="0">{props.zero}</option>
}


    const options=props.options.map((option) =>
    <option key={option.id} value={option.id}>{option.name}</option>
    );


   
      const input=<select onChange={(e) => {
        changeInputValue(e);
    }} name={props.name} value={props.data[props.name]?props.data[props.name]:""}>{empty}{options}</select>

    
   /* const input=<input name={props.name} value={props.data[props.name]} 
    onChange={(e) => {
        changeInputValue(e);
    }} />;
   const d={props.edit==true?:{props.data[props.name]}};
   
    */
    
    const selected=props.options.find(options => options.id === props.data[props.name]);
    
   const content=(props.edit==true?input:(selected?selected.name:"--not found--"))

   
	return (<div className="input"><label>{props.label}</label>{content}</div>);
}
