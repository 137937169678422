

import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import TopNav from "./TopNav.js";
import Period from "./form/Period.js";
import InputText from "./form/InputText.js";
import InputDouble from "./form/InputDouble.js";
import InputSelect from "./form/InputSelect.js";
import { apiClient } from "../apiCall";
export const defaultNew={

	value:null,
	effective_from:0,
	description:"",
	persons_id:0,
	inited:false

};


export default function CreditCard() {



	
	const { state, dispatch } = useStore();
	const {common}=state;
	console.log("CCIMMIN",state);
	const apiCall = apiClient(state, dispatch);
	const [NewData, setNewData] = useState({
		...defaultNew,
		effective_from:null
		
    });
	useEffect(() => {


			if(common){
				
				if(!NewData.effective_from || NewData.effective_from==0){
					setNewData({...NewData,effective_from:common.current_period,inited:true});
				}
			}
	  }, [common]);



	//console.log("LOADIN",);
	if(common.loading || state.users.loading || !NewData.effective_from){
		//console.log("NULLL");
		return null;
	}
 /*  const listItems=common.ownbuys.map(buy=>(

	return   <tr key={buy.id}></tr>

   ));
*/

const tryToSave=(e)=>{

	e.preventDefault();

	let url="creditcard";
	let data={...NewData};
	data.value=parseFloat(data.value);
	apiCall.ADD(url, data,function(status,err_msgs=null){

		if(status==400){

			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
		//	setUserData({...UserData,"edit":false});

			setNewData({...NewData,value:null,description:"",
			persons_id:0});


			apiCall.GET("common");
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});





}
const formatDate=(date)=>{

	let dates=date.split("-");
  
    if(dates.length===3){
        return dates[1]+"/"+dates[0];
    }
	return null;

}
const getPersonName=(id)=>{

	let user=(state.users.items.find(user => user.id ===id));

	if(!user){
		return null;	
	}
	return user.lastname+" "+user.firstname;
}



const selected=common.ownbuys.filter(function(buy){

	return buy.palkkakausi===NewData.effective_from && buy.is_positive===0;

});


   const listItems=selected.map(buy=>(


<tr key={buy.id}><td>{formatDate(buy.palkkakausi)}</td><td>{getPersonName(buy.persons_id)}</td><td>{buy.value}</td><td>{buy.description}</td></tr>

));


   const changeValue=(n,v,index=null)=>{
	
	setNewData({...NewData,[n]:v});

   };

   console.log("LÄPI RENDER PERSON ID",NewData.persons_id);
//state.ccommon
/*const persons=[
	
	
	{id:1,name:"juk"}



];*/
const persons=state.users.items.map(person=>(


	{id:person.id,name:person.lastname+" "+person.firstname}
	
	));

    return (<div className="creditcard content">
    <TopNav  />
    
    <div className="inner">
				<div className="box">
				<h2>Netto vähennykset</h2>
<div className="nettoform">
	<div className="col"><label>Kausi</label><Period min={state.common.min_wage_period} max={state.common.max_wage_period} onChange={changeValue} name="effective_from" value={(NewData.effective_from)} edit={true}/></div>

	<div className="col"><InputSelect label="Henkilö" onChange={changeValue} zero="--Valitse henkilö--" options={persons} data={NewData} name="persons_id" edit={true}/></div>
	<div className="col"><InputDouble label="Määrä" onChange={changeValue} data={NewData} name="value" edit={true}/></div>
	<div className="col"><InputText label="Kuvaus" onChange={changeValue} data={NewData} name="description" edit={true}/></div>
	<div className="col store"><button onClick={tryToSave}>Tallenna</button></div>
</div>
			

		<table className="table">
			<thead>
				<tr><th>Kohdekausi</th><th>Henkilö</th><th>Summa</th><th>Kuvaus</th></tr>
			
			</thead>
			<tbody>
				
				{listItems}
					
			</tbody>		
			
			
		</table></div>
		</div></div>


   )





};
