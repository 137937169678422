import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputText from "./form/InputText.js";
import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import Period from "./form/Period.js";
import CustomDate from "./form/CustomDate.js";
import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";


import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import { apiClient } from "../apiCall";
import TopNav from "./TopNav.js";
import PersonRolesEditor from "./form/PersonRolesEditor.js";


import {
	browserHistory,
    useParams
  } from "react-router-dom";
import {
	
	
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";



 
  export const defaultEventData={
	
	name:"",
    active:0,
	datetoadd:null,
	dates:[]
	
};

export const DefaultRequestErrors={

	errors:null
}
export default function AddEvent() {
	const { state, dispatch } = useStore();
    let { id } = useParams();
	



	const [IsStored, setIsStored] = useState(false);
    const [EventData, setEventData] = useState({
		...defaultEventData
		
    });
	const [RequestErrors,setRequestErrors] = useState({
		...DefaultRequestErrors
	});

		const onFocus=(name,value,index)=>{
			let key=name;
		
			let errors={...RequestErrors.errors};
			errors[key]=null;
			setRequestErrors({errors:{...errors}});
		};


	const apiCall = apiClient(state, dispatch);
	
	const changeInputValue = (name,value) => {
	

		setEventData({...EventData,[name]:value});
 
 
	}
	
	
	const tryToSave=()=>{

		let url="events";
		let data={...EventData};
		
		apiCall.ADD(url, data,function(status,err_msgs=null){
		
			if(status==400){

				console.log(err_msgs);
				console.log(RequestErrors);
			
				setRequestErrors({errors:{...err_msgs}});
	
			
			}else if(status==200){

				
			
				setIsStored(false);
			}
	
		}).then(() => {
			//apiCall.GET('destinations');
			
		});
	
	
	
	
		
	};
	const addNewDate=(e)=>{

		setEventData({...EventData,dates:[...EventData.dates,EventData.datetoadd],datetoadd:null})
		
	}
	const addAnother=(e)=>{


    };
	if(IsStored){

		return (<div><div className="thanks"><h1>Event added</h1><p><a onClick={addAnother}>Lisää uusi</a></p></div></div>);
	}
	const dates=EventData.dates.map((date,index) =>{

		return <span key={index}>{date}</span>;
	  
	});
	console.log(EventData);


	return (
		<div className="persons content">
		<TopNav />
		<div className="inner">
			
		<div className="person content">
		
				<div className="inner">
				<div className="box">

				<h2>{EventData.name} </h2>

				

			



					<div className="form-block">

						
						
						<div className="left">
							<InputText label="Name of the event" onChange={changeInputValue} data={EventData} onFocus={onFocus} errors={RequestErrors.errors} useSimpleKey={true} name="name" edit={true}/>
						<div>
							<CustomDate useSimpleKey={true}  label="Päivämäärä" name="datetoadd" onChange={changeInputValue} data={EventData} onFocus={onFocus} errors={RequestErrors.errors} edit={true}/>
							<span onClick={addNewDate}>+</span>
						</div>
						<div className="dates">
							{dates}
						</div>





					
						</div>
						



					

					</div>
				
					<div className="btn-block">
					<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>

					</div>
					

					
				</div>
					
			</div>
			
			
		</div>
		</div>
		</div>
	);
}
