import React,{useState,useEffect} from "react";
import { useStore } from "../store/useStore";
import { searchSetting } from "../commonFunctions";
import axios from "axios";
import { config } from "../config";
import InputTextTranslations from "./form/InputTextTranslations.js";
import InputText from "./form/InputText";

import Alaiset from "./Alaiset.js";
import InputSelect from "./form/InputSelect.js";
import InputClock from "./form/InputClock.js";


import SettingPair from "./form/SettingPair.js";
import Settings from "./form/Settings.js";
import EsimiesAndRole from "./form/EsimiesAndRole.js";

import { getSettingValue,getSettingType,getSettingIndex,getCommonSetting } from "../commonFunctions";

import TaxRates from "./form/TaxRates.js";
import Loans from "./form/Loans.js";
import BonusTable from "./BonusTable.js"
import PersonTopNav from "./PersonTopNav.js";
import ShowTopNav from "./ShowTopNav.js";

import { apiClient } from "../apiCall";
import CustomDate from "./form/CustomDate.js";

import {
  
    useParams
  } from "react-router-dom";
import {
	BrowserRouter as Router,
	StaticRouter, // for server rendering
	Route,
	Link
	// etc.
  } from "react-router-dom";
import { getInstalledBinaries } from "node-sass/lib/extensions";


  
  export const defaultShowData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};
export const defaultEventsData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};
export const defaultPlacesData={

	loaded:false,
	data:null,
	orginal:null,
    edit:false
	
};
export const DefaultRequestErrors={

	errors:null
}
export default function ShowPerformances() {
	const { state, dispatch } = useStore();
    const { show_id,event_id } = useParams();
  
    const [ShowData, setShowData] = useState({
		...defaultShowData
		
    });
	const [EventsData, setEventsData] = useState({
		...defaultEventsData
		
    });
	const [PlacesData, setPlacesData] = useState({
		...defaultPlacesData
		
    });
	
	const [RequestErrors,setRequestErrors] = useState({
	...DefaultRequestErrors
		
    });
	
    const addPerformer=(target)=>{
        var data={...ShowData.data};
        var showtimes=[...ShowData.data.showtimes];
       
        data.showtimes=[{time:"00:00:00",place_id:null,time:null,data:null,event_dates_id:null,event:{id:null},place:{id:null} },
         ...showtimes];
       
        setShowData({...ShowData,data:data});
  
      }
	const apiCall = apiClient(state, dispatch);

	

    useEffect(() => {
		console.log("FETCH",show_id);
		fetch( config.apiUrl+"/shows/"+show_id,{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setShowData({data:{...data},orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, [show_id]);


	  useEffect(() => {
		
		fetch( config.apiUrl+"/places",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setPlacesData({data:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, []);

	  useEffect(() => {
		
		fetch( config.apiUrl+"/events",{headers: new Headers({
			'Authorization': 'Bearer '+state.user.token
			
		  })})
		  .then((res) => res.json())
		  .then(data => setEventsData({data:[...data],orginal:JSON.parse(JSON.stringify((data))),loaded:true}))

	  }, []);


const changeEditMode=()=>{

	setShowData({...ShowData,edit:!ShowData.edit})

};

const getEventTopic=()=>{

	if(event_id){
		return <h3>{ShowData.data.events.find(x => x.id == event_id).name}</h3>;
	}
	return null;
}


const tryToSave=()=>{

let url="shows/"+show_id+"/showtimes";
	let data={showtimes:[...ShowData.data.showtimes]};
	
	apiCall.ADD(url, data,function(status,err_msgs=null,v){
	
		if(status==400){
			console.log(err_msgs);
			console.log(RequestErrors);
			//setErrors({errors:{...err_msgs}})
			setRequestErrors({errors:{...err_msgs}});
			//setFolderData({...FolderData,errors:err_msgs})
		}else if(status==200){
			//setShowData({...ShowData,"edit":false});
			let data=v.data;
			console.log("REC",data);
			setShowData({...ShowData,edit:false,data:{...data},orginal:JSON.parse(JSON.stringify((data)))});


			setRequestErrors({errors:null});
			
		}

	}).then(() => {
		//apiCall.GET('destinations');
		
	});
    




	
};
const onFocus=(name,value,index)=>{
	let key="data."+name;
	console.log(RequestErrors);

	let errors={...RequestErrors.errors};
	errors[key]=null;
	setRequestErrors({errors:{...errors}});
};

console.log("COMMON",state.common.loading);
	if(!ShowData.loaded || !EventsData.loaded || !PlacesData.loaded){

		return (<div></div>);
	}
    if(state.common.loading){
        return (<div></div>);
    }
    console.log(state.common);
	/*const rows=EventData.data.shows.map((show,index) =>{

		return <tr key={index}>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{show.texts.fi.name}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{genres(show.genres)}</Link></td>
			<td><Link to={"/events/"+show_id+"/show/"+show.id}>{showtimes(show.showtimes)}</Link></td>
			</tr>;
	  
	});*/
  
    const changeInputValue=(name,value,index)=>{
        var data={...ShowData.data};
        var performers=[...ShowData.data.performers];
        performers[index][name]=value;
        setShowData({...ShowData,data:data});





    };
	const removePerformance=(index)=>{

		


		let showtimes=[...ShowData.data.showtimes];
		showtimes=showtimes.filter((value, curindex) => index !== curindex); 
	
	
		setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}});



	};
const changeEvent=(n,v,index)=>{

	let showtimes=[...ShowData.data.showtimes];
	showtimes[index]={...showtimes[index],event:{id:v},event_dates_id:null,date:null};

	setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})
	console.log("upd this",	showtimes[index])
};
const changeClock=(n,v,index)=>{

	let showtimes=[...ShowData.data.showtimes];
	showtimes[index]={...showtimes[index],time:v};

	setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})
};
const changeDate=(n,v,index)=>{

	let showtimes=[...ShowData.data.showtimes];
	showtimes[index]={...showtimes[index],event_dates_id:v};
	setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})
	
/*
	let showtimes=[...ShowData.data.showtimes];
	showtimes[index]={...showtimes[index],event:{id:v},event_dates_id:null,date:null};

	setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})
	console.log("upd this",	showtimes[index])*/


};
	console.log("EventsData.data",EventsData.data);
	const eventOptions=EventsData.data.map((event,index)=>{



		return {id:event.id,name:event.name}
		
	});

	const placesOptions=PlacesData.data.map((place,index)=>{



		return {id:place.id,name:place.texts.fi.name}
		
	});
	const changePlace=(n,v,index)=>{
		
		let showtimes=[...ShowData.data.showtimes];
		showtimes[index]={...showtimes[index],place_id:v,place:{...showtimes[index].place,id:v}};
		setShowData({...ShowData,data:{...ShowData.data,showtimes:[...showtimes]}})


	};
	const eventDatesOptions=(event_id)=>{

		let event= EventsData.data.find(e => e.id ==event_id);
		

		if(event && event.dates){
		
			return event.dates.map((d,index)=>{
				return {id:d.id,name:d.date}
				
			});
		}


		return [];

	};

	console.log("PAIIKAT",placesOptions);
    const rows=ShowData.data.showtimes.map((show,index) =>{

        return <tr key={index}><td>

		{ShowData.edit?<InputSelect edit={ShowData.edit}  zero={"Select event"} index={index} onChange={changeEvent} options={eventOptions} name="id" data={show.event}/>:show.event.name}
           </td>
            
            <td>
			{ShowData.edit?<div><InputSelect zero={"valitse"} edit={ShowData.edit} index={index} onChange={changeDate} options={eventDatesOptions(show.event.id)} name="event_dates_id" data={show}/></div>:show.date}

			
</td>
<td>
{(ShowData.edit?<InputClock onChange={changeClock} name="time" data={show} index={index}/>:show.time)}
</td>
<td>
{ShowData.edit?<div><InputSelect zero={"valitse"} edit={ShowData.edit} index={index} onChange={changePlace} options={placesOptions} name="id" data={show.place}/></div>:show.place.texts.fi.name}


</td>
<td>
		
</td>
<td>{ShowData.edit?<span onClick={(e) => {
      				  removePerformance(index);
   				 }} class='remove-me'>Remove</span>:null}</td>
</tr>;
      
    });
	console.log("SHOW",ShowData);
	



	return (

		<div className={"mycontent event-content"}>
			
				<div className="inner">
			
				<div className="box">
				{getEventTopic()}
				<h2>{ShowData.data.texts.fi.name}</h2>

				{<div className={"edit-btn "+(ShowData.edit?"active":"")} onClick={(e) => {
      				  changeEditMode(e);
   				 }}><span>Muokkaa</span></div>}



					{ShowData.edit?<div className="save-btn" onClick={tryToSave}><span>Tallenna</span></div>:null}

<ShowTopNav data={ShowData.data} id={ShowData.data.id}/>
					

					<div className="form-blocks">
					<div className="form-block">

						
			{	/*'name',"group_name","description","short_description","country","language","suitability"*/ 		}
						<div class="wide">
                       


                        <table class="table-new fringetable"><thead><tr><th>Event</th><th>Date</th><th>Time</th><th>Place</th><th>{ShowData.edit?
						<div className="edit-btn" onClick={(e) => {
      				 addPerformer();
   				 }}><span>+</span></div>:null}
</th></tr></thead><tbody>{rows}</tbody></table>
                        </div>
					


					</div>
					<div className="form-block">

						
					</div>
					
					</div>

					
				</div>
					
			</div>
			
			
		</div>
	);
}
